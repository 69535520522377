import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BarsArrowDownIcon } from '@heroicons/react/24/solid';
import './style.css'; 

export default function index(props) {
  const {tabs, activeDropDown, setActiveDropDown} = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickItem = (value) => {
    setIsOpen(false);
    setActiveDropDown(value);
  }

  return (
    <div className="dropdown">
      <div className="dropdown-button" onClick={toggleDropdown}>
        <div className="text-primary">{activeDropDown}</div>
        <BarsArrowDownIcon strokeWidth={2} className="h-5 w-5 text-primary" />
      </div>
      {isOpen && (
        <div className="dropdown-content">
          {
            tabs.map((data) => {
              return <a href="#" onClick = {() => {handleClickItem(data)}}> {data}</a>
            })
          }
        </div>
      )}
    </div>
  );
};
