import React, {useState, useEffect} from 'react'
import { saveAdminStatus, getStatus } from '../../util/admin-api'
import { Switch, Spinner } from "@material-tailwind/react";

export default function Stamp() {
  const [listStatus, setListStatus] = useState(false);
  const [buyStatus, setBuyStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInitialStatus();
  }, [])

  const handleUpdateStatus = async (terms, status) => {
    await saveAdminStatus(terms, status ? 1 : 0);
  }

  const getInitialStatus = async () => {
    setLoading(true);
    let res = await getStatus("stamp_list");
    setListStatus(res.data.status == 1 ? true : false);
    res = await getStatus("stamp_buy");
    setBuyStatus(res.data.status == 1 ? true : false);
    setLoading(false);
  }

  const handleListStatus = async () => {
    setLoading(true);
    setListStatus(!listStatus);
    await handleUpdateStatus("stamp_list", !listStatus);
    setLoading(false);
  }

  const handleBuyStatus = async () => {
    setLoading(true);
    setBuyStatus(!buyStatus);
    await handleUpdateStatus("stamp_buy", !buyStatus);
    setLoading(false);
  }

  return (
    <div className="flex flex-col gap-5 justify-center items-center">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed" color="pink"/> : <></>
      }
      <div className="text-[24px] font-bold mt-5">Stamp Status</div>
      <div className="sm:w-[400px] w-[320px] flex flex-row gap-3 items-center">
        <div className="text-white font-bold text-[18px] mr-10 sm:w-[240px] w-[160px]">List Status (OFF=ON)</div>
        <Switch color="red" checked = {listStatus} onChange={handleListStatus}/>
      </div>
      <div className="sm:w-[400px] w-[320px] flex flex-row gap-3 items-center">
        <div className="text-white font-bold text-[18px] mr-10 sm:w-[240px] w-[160px]">Buy Status (OFF=ON)</div>
        <Switch color="red" checked = {buyStatus} onChange={handleBuyStatus}/>
      </div>
    </div>
  )
}
