import React, {useState, useEffect, useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Brc20 from '../components/Explorer/Brc20'
import Dmt from '../components/Explorer/Dmt'
import Tap from '../components/Explorer/Tap'
import Unat from '../components/Explorer/Unat'
import Rune from '../components/Explorer/Rune'
import Activity from '../components/Explorer/Activity';
import Inscription from '../components/Explorer/Inscription';
import Names from '../components/Explorer/Names';
import Stamp from '../components/Explorer/Stamp';
import PointCatalog from '../components/Explorer/PointCatalog';
import { formatAddress } from '../util/format-data';
import toast, { Toaster } from 'react-hot-toast';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { debounce } from 'lodash';
import { latestDomain } from '../util/api';
import { getPointData } from '../util/api';
import { 
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
  Button
} from '@material-tailwind/react';

import {
  Routes,
  Route,
  useNavigate,
  Link
} from "react-router-dom";
import { updateKey } from '../store/action';
import { useParams } from 'react-router-dom';
import Src20 from '../components/Explorer/Src20';
import Lrc20 from '../components/Explorer/Lrc20';
import Bitmap from '../components/Explorer/Bitmap';
import Dropdown from '../components/Dropdown/index';
import Arc20 from '../components/Explorer/Arc20';

export default function Profile() {
  let { id } = useParams();
  const wallet = useSelector(state => state.wallet);
  const [keyAddress, setKeyAddress] = useState(wallet.nostrOrdinalsAddress);

  const navigate = useNavigate();
  const [tab, setTab] = useState(id ? id : 0); // 0: brc20, 1: tap, 2: dmt, 3: unat
  const [point, setPoint] = useState(0);

  let tempActive = "ALL";
  if (id == 2) {
    tempActive = "TAP";
  }
  else if (id == 3) {
    tempActive = "DMT";
  }
  else if (id == 4) {
    tempActive = "UNAT";
  }
  else if (id == 7) {
    tempActive = "NAMES";
  }
  else if (id == 12) {
    tempActive = "BITMAP";
  }
  else if (id == 11) {
    tempActive = "LRC-20";
  }
  const [activeDropDown, setActiveDropDown] = useState(tempActive);
  const dropdownTabs = ["ALL", "BRC-20", "RUNES", "TAP", "DMT", "UNAT", "BITMAP", "LRC-20", "SRC-20", "STAMPS", "ARC-20", "NAMES"];

  useEffect(() => {
    switch (activeDropDown) {
      case "ALL":
        setTab(0);
        break;
      case "BRC-20":
        setTab(1);
        break;
      case "RUNES":
        setTab(5);
        break;
      case "TAP":
        setTab(2);
        break;
      case "DMT":
        setTab(3);
        break;
      case "UNAT":
        setTab(4);
        break;
      case "BITMAP":
        setTab(12);
        break;
      case "LRC-20":
        setTab(11);
        break;
      case "SRC-20":
        setTab(9);
        break;
      case "STAMPS":
        setTab(10);
        break;
      case "NAMES":
        setTab(7);
        break;
      case "ARC-20":
        setTab(13);
        break;
    }
  }, [activeDropDown])

  useEffect(() => {
    setKeyAddress(wallet.nostrOrdinalsAddress);
  }, [wallet.nostrOrdinalsAddress])
  
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        toast.success('Address copied to clipboard!')
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  useEffect(() => {
    const fetchPoints = async () => {
      if (keyAddress != "" && keyAddress != undefined) {
        let result = await getPointData(
          {
            address: keyAddress
          }
        )
        if (result.total < 0) 
          setPoint(0);
        else
          setPoint(result.total ?? 0);
      }
      else setPoint(0);
    }
    fetchPoints(0);
  }, [keyAddress])

  const handleMint = () => {
    navigate('/register');
  }
  return (
    <div className="text-white mt-[65px] w-full md:px-7 px-2 flex flex-col items-center mb-10 min-h-[600px]">
      <div className="mt-4 w-full">
        {
          keyAddress == "" ? <></> :
          <div className = "flex flex-row items-center gap-2">
            <div className="md:text-[32px] text-[22px] font-semibold">{formatAddress(keyAddress)}</div>
            <div className="flex flex-row items-center bg-primary px-2 py-1 rounded-md cursor-pointer" onClick={() => {handleCopy(keyAddress)}}>
              <DocumentDuplicateIcon className="h-5 w-5 cursor-pointer" />
              <span>Copy</span>
            </div>
            <div className="sm:text-[24px] text-[20px] font-bold">
              <span className="text-white">Points:&nbsp;</span>
              <span className="text-primary">{point}</span></div>
          </div>
        }
      </div>
      <div className="sm:flex hidden md:flex-row flex-wrap md:gap-2 gap-1 w-full mt-5 font-title">
        <div className={`md:text-[18px] text-[14px] ${tab == 0 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(0)}>ALL</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 1 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(1)}>BRC-20</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 5 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(5)}>RUNES</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 2 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(2)}>TAP</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 3 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(3)}>DMT</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 4 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(4)}>UNAT</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 12 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(12)}>BITMAP</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 11 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(11)}>LRC-20</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 9 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(9)}>SRC-20</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 10 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(10)}>STAMPS</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 13 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(13)}>ARC-20</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 7 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(7)}>NAMES</div>
        <div className={`md:text-[18px] text-[14px] px-2 py-1 bg-primary hover:bg-primary-hover cursor-pointer text-white rounded-md`} onClick={() => setTab(6)}>ACTIVITY</div>
        {/* <div className={`md:text-[18px] text-[14px] px-2 py-1 bg-primary hover:bg-primary-hover cursor-pointer text-white rounded-md`} onClick={() => setTab(8)}>POINTS</div> */}
      </div>
      <div className="sm:hidden flex flex-row gap-3 w-full items-center mt-5">
        <Dropdown tabs = {dropdownTabs} setActiveDropDown = {setActiveDropDown} activeDropDown = {activeDropDown}/>
        <div className={`text-[18px] w-full text-center px-2 py-2 bg-primary hover:bg-primary-hover cursor-pointer text-white rounded-md`} onClick={() => setTab(6)}>ACTIVITY</div>
      </div>
      {
        tab == 0 ? <Inscription keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 1 ? <Brc20 keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 2 ? <Tap keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 3 ? <Dmt keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 4 ? <Unat keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 5 ? <Rune keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 6 ? <Activity keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 7 ? <Names keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 8 ? <PointCatalog keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 9 ? <Src20 keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 10 ? <Stamp keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 11 ? <Lrc20 keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 12 ? <Bitmap keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 13 ? <Arc20 keyAddress= {keyAddress} /> : <></>
      }
    </div>
  )
}
