const oklinkUrl = "https://www.oklink.com";

export const getExtraAddressTokenList = async (page, limit, chainShortName, address, protocolType, tokenInscriptionId = "", symbol = "", projectId = "") => {

  const url = `${oklinkUrl}/api/v5/explorer/inscription/address-token-list?chainShortName=${chainShortName}&address=${address}&protocolType=${protocolType}&tokenInscriptionId=${tokenInscriptionId}&symbol=${symbol}&projectId=${projectId}&page=${page}&limit=${limit}`;

  const data = {
    url: url
  };

  let response  = await fetch("https://api.tapwallet.io/extra/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}
