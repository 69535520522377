import React from 'react'
import { useEffect, useState } from 'react'
import { amountFormat } from '../../util/format-data'
import toast, { Toaster } from 'react-hot-toast';
import { getUtxos, getConfirmedUtxos, getDummyPsbt, calculateFee, getFeeRateUpdated, buyOrdinals, getBtcUtxos } from '../../util/ordinal';
import FeeRateCardNew from '../FeeRateCardNew';
import { saveMarketplaceData, getPaymentTx } from '../../util/api';
import { formatAddress } from '../../util/format-data';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { formatInscriptionId } from '../../util/format-data';
import { useSelector, useDispatch } from 'react-redux';
import { lambdaAddress, marketplaceFeeLimit, encodedAddressPrefix, padding, tip, tippingAddress, royalty } from '../../configs/constant';
import { BitcoinNetworkType, signMessage, signTransaction, sendBtcTransaction } from 'sats-connect';
import { getFeeRate, bytesToHex, buf2hex, textToHex, hexToBytes, getMempoolUtxos, loopTilAddressReceivesMoney, waitSomeSeconds, addressReceivedMoneyInThisTx, pushBTCpmt, calculateFeeReal, getData, isValidTaprootAddress} from '../../util/inscribe-util';
import { useWallet, useWallets } from '@wallet-standard/react';
import { getPaymentUtxosRunes } from '../../util/new-api';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Slider,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Spinner,
  Input
} from "@material-tailwind/react";

import { latestTokenNew } from '../../util/new-api';
import { savePointData } from '../../util/api';
import { updateKey } from '../../store/action';
import { useNavigate, useLocation } from "react-router-dom";
import ChatButton from '../Chat/ChatButton';
import { getFilteredUtxos } from '../../util/ordinal';
import { formatFloat } from '../../util/format-data';
import { getLRC20TokenDetail } from '../../util/lamda-api';
import { saveTokenTransferMintData } from '../../util/api';
import { getLrc20SendTx } from '../../util/new-api';

export default function Lrc20Card(props) {
  
  const wallet = useSelector(state => state.wallet);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { wallets } = useWallets();

  const SatsConnectNamespace = 'sats-connect:';

  const { Address, Script, Signer, Tap, Tx } = window.tapscript;
  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const { data, price, handleRefresh, handleActivity, type} = props

  const feeRateTabs = ["Slow", "Normal", "Fast", "Custom"];

  const [feeRateMode, setFeeRateMode] = useState("Normal");
  const [feeRates, setFeeRates] = useState({});
  const [feerate, setFeerate] = useState(0);
  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [mode, setMode] = useState(1);
  const [showSpinner, setShowSpinner] = useState(false);
  const [customFee, setCustomFee] = useState(0);
  const [currentFee, setCurrentFee] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let intervalId;

    const updateFees = async () => {
      if (show) {
        let response = await getFeeRate();
        setFeeRates(response);
        setFeerate(response[feeRateMode]);
        if (customFee == 0 || customFee == NaN) 
        {
          setCustomFee(response["Fast"] + 1);
        }

        if (feeRateMode == "Custom") {
            setCurrentFee(customFee);
            setTotalFee(calculateInscribeFee(customFee)["totalFee"]);
        }
        else {
            setCurrentFee(response[feeRateMode]);
            setTotalFee(calculateInscribeFee(response[feeRateMode])["totalFee"]);
        }
      }
    }
    updateFees();
    intervalId = setInterval(updateFees, 10 * 1000);

    return () => {
      clearInterval(intervalId);
    }
  }, [customFee, feeRateMode, show, sliderValue])

  useEffect(() => {
    if (sliderValue != 0) {
      let value = (sliderValue / 100) * (500 - feeRates["Normal"]) + feeRates["Normal"];
      setCustomFee(Math.floor(value));
    }
  }, [sliderValue])

  const handleAddress = () => {
    let keyData = {address: data.ownerAddress, domain: data.domain ? data.domain: ""};
    dispatch(updateKey(keyData));
    navigate('/explorer');
  }

  const handleCustomFee = (value) => {
    const sanitizedValue = value.replace(/[^0-9.]/g, '');
    setCustomFee(sanitizedValue);
  }
  
  const calculateInscribeFee = (feeRate) => {
    const text = `{"p":"lam","op":"call","contract":"${data.ticker}","function":"transferFrom","args":["${data.ownerAddress}", "${wallet.nostrOrdinalsAddress}", ${data.amount}]}`;

    let dataSize = text.length;
    let mintCount = 1;
    if (mintCount <= 0 || mintCount == NaN) mintCount = 1;
    let base_size = 160;
    let prefix = 160;
    let txsize = prefix + Math.floor(dataSize / 4);
    let inscriptionLength = mintCount;
    let inscriptionFee = padding ;
    let networkFee = Math.floor(feeRate * txsize * 1.1);
    let royalty = 0;

    let royaltyFee = royalty * mintCount;
    let serviceFee = 0;
    let senderFee = feeRate * (10 + 10 + 57.5 * 2 + 43);
    let marketplaceFee = marketplaceFeeLimit * 2;
  
    let totalFee = Math.ceil(inscriptionFee * mintCount + networkFee * mintCount + senderFee + marketplaceFee + data.actualPrice);
    return {
      "inscriptionFee": Math.ceil(inscriptionFee * mintCount + networkFee * mintCount),
      "senderFee": senderFee,
      "marketplaceFee": Math.ceil(marketplaceFee),
      "totalFee": totalFee
    }
  }

  const handleBuy = () => {
    setShow(true);
  }

  const handleMint = async () => {
    setShowSpinner(true);
    if (wallet.nostrOrdinalsAddress == "") {
      toast.error("Please connect your wallet!");
      setShowSpinner(false);
      setShow(false);
      return;
    }

    // Get LRC20Token Deail Data.
    let tokenDetailDatas = await getLRC20TokenDetail(data.ticker);
    // check balance
    for(let tempData of tokenDetailDatas["state"]["_balance"]) {
      if (tempData[0] == data.ownerAddress) {
        if (tempData[1] < data.amount) { // delist
          let params = {
            id : data.id,
            reason: "delist",
            psbt: ""
          }
          await saveMarketplaceData(params);

          let pointData = {
            address : wallet.nostrOrdinalsAddress,
            point : -1,
            reason : "Delist Lrc20",
            deleteFlag : 0
          }
          await savePointData(pointData);

          toast.info("You can't buy this item anymore!");
          setShowSpinner(false);
          setShow(false);
          handleRefresh();
          return;
        }
        break;
      }
    }

    let flag = 0;
    let confirmed = 0;
    for(let tempData of tokenDetailDatas["state"]["_allowance"]) {
      if (tempData[0] == data.ownerAddress) {
        for(let tempData1 of tempData[1]) {
          if (tempData1[0] == lambdaAddress) {
            if (tempData1[1] >= data.amount) {
              flag = 1;
              confirmed = 1;
              break;
            }
          }
        }
      }
      if (flag == 1) break;
    }

    if (confirmed == 0) {
      toast.error("Please wait for buying this item..")
      setShowSpinner(false);
      setShow(false);
      return;
    }

    const mintText = `{"p":"lam","op":"call","contract":"${data.ticker}","function":"transferFrom","args":["${data.ownerAddress}", "${wallet.nostrOrdinalsAddress}", ${data.amount}]}`;

    if (!typeof window) return
    if (!window.tapscript) return

    let cryptoUtils = window.cryptoUtils;
    const KeyPair = cryptoUtils.KeyPair;

    let fees = calculateInscribeFee(currentFee);

    let privkey = bytesToHex(cryptoUtils.Noble.utils.randomPrivateKey());
    // privkey = "99c2af49b93a97ffb4690a5f91a7e7dd0930e0e8caadef70f2c2575d5822e45c"

    console.log(privkey);

    let seckey = new KeyPair(privkey);
    let pubkey = seckey.pub.rawX;

    const ec = new TextEncoder();

    const init_script = [
      pubkey,
      'OP_CHECKSIG'
    ];
    
    const init_script_backup = [
        '0x' + buf2hex(pubkey.buffer),
        'OP_CHECKSIG'
    ];

    let init_leaf = await Tap.tree.getLeaf(Script.encode(init_script));
    let [init_tapkey, init_cblock] = await Tap.getPubKey(pubkey, {target: init_leaf});

    const test_redeemtx = Tx.create({
      vin  : [{
          txid: 'a99d1112bcb35845fd44e703ef2c611f0360dd2bb28927625dbc13eab58cd968',
          vout: 0,
          prevout: {
              value: 10000,
              scriptPubKey: [ 'OP_1', init_tapkey ]
          },
      }],
      vout : [{
          value: 8000,
          scriptPubKey: [ 'OP_1', init_tapkey ]
      }],
    });
    
    const test_sig = await Signer.taproot.sign(seckey.raw, test_redeemtx, 0, {extension: init_leaf});
    test_redeemtx.vin[0].witness = [ test_sig.hex, init_script, init_cblock ];
    const isValid = await Signer.taproot.verify(test_redeemtx, 0, { pubkey });

    if(!isValid)
    {
      alert('Generated keys could not be validated. Please reload the app.');
      return;
    }

    let files = [];

    let mimetype = "text/plain;charset=utf-8";
    let salts = "";

    for(let i = 0; i< 1; i++)
    {
      let text = mintText;
      files.push({
        text: JSON.stringify(text),
        name: textToHex(text),
        hex: textToHex(text),
        mimetype: mimetype,
        sha256: ''
      });
    }

    let inscriptions = [];
    let inscriptionAddressList = [];
    let inscriptionAmountList = [];

    let recipientList = [];

    for (let i = 0; i < files.length; i++) {

      const hex = files[i].hex;
      const data = hexToBytes(hex);
      const mimetype = ec.encode(files[i].mimetype);

      const script = [
          pubkey,
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          ec.encode('ord'),
          '01',
          mimetype,
          'OP_0',
          data,
          'OP_ENDIF'
      ];

      const script_backup = [
          '0x' + buf2hex(pubkey.buffer),
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          '0x' + buf2hex(ec.encode('ord')),
          '01',
          '0x' + buf2hex(mimetype),
          'OP_0',
          '0x' + buf2hex(data),
          'OP_ENDIF'
      ];

      const leaf = await Tap.tree.getLeaf(Script.encode(script));
      const [tapkey, cblock] = await Tap.getPubKey(pubkey, { target: leaf });

      let inscriptionAddress = Address.p2tr.encode(tapkey, encodedAddressPrefix);

      let prefix = 160;

      let txsize = prefix + Math.floor(data.length / 4);

      inscriptionAddressList.push(inscriptionAddress);

      inscriptions.push(
        {
          leaf: leaf,
          tapkey: tapkey,
          cblock: cblock,
          inscriptionAddress: inscriptionAddress,
          txsize: txsize,
          fee: fees.inscriptionFee - padding,
          script: script_backup,
          script_orig: script
        }
      );
    }
    
    let _fundingAddress = Address.p2tr.encode(init_tapkey, encodedAddressPrefix);

    // get payment utxos
    inscriptionAddressList.push(lambdaAddress);
    inscriptionAddressList.push(data.ownerAddress);
    inscriptionAddressList.push(tippingAddress);

    inscriptionAmountList.push(fees.inscriptionFee);
    inscriptionAmountList.push(fees.senderFee);
    inscriptionAmountList.push(data.actualPrice);
    inscriptionAmountList.push(fees.marketplaceFee);

    let tempfeerate = currentFee;
    if (wallet.domain == "xverseWallet") tempfeerate = Math.ceil(currentFee * 1.2);
    const paymentUtxos = await getPaymentUtxosRunes(wallet.nostrPaymentAddress, inscriptionAddressList, inscriptionAmountList, lambdaAddress, 0, wallet.paymentPublicKey, tempfeerate, inscriptionAddressList.length, wallet.domain)

    let isSuccess = true;

    if (paymentUtxos.status == "fail") {
      alert("Insufficient balance.");
      setLoading(false);
      setShow(false);
      setShowSpinner(false);
      isSuccess = false;
      return;
    }

    try{
      if (wallet.domain == "tapwallet") {
        const signedPsbt = await window.tapwallet.signPsbt(paymentUtxos.psbt);
        const txid = await window.tapwallet.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "unisat") {
        const signedPsbt = await window.unisat.signPsbt(paymentUtxos.psbt);
        const txid = await window.unisat.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "okxwallet") {
        const signedPsbt = await window.okxwallet.bitcoin.signPsbt(paymentUtxos.psbt);
        const txid = await window.okxwallet.bitcoin.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "xverseWallet") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          await signTransaction({
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
              
            },
            onCancel: () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                setShow(false);
                setShowSpinner(false);
                return;
            },
          });
  
        }
        else {
          alert("Insufficient balance.");
          setLoading(false);
          setShow(false);
          isSuccess = false;
          setShowSpinner(false);
          return;
        }
      }
      if (wallet.domain == "magiceden") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

          await signTransaction({
            getProvider: async () =>
              magicedenWallets[0].features['sats-connect:'].provider,
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
            },
            onCancel: () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                setShow(false);
                setShowSpinner(false);
                return;
            },
          });
  
        }
        else {
          alert("Insufficient balance.");
          setLoading(false);
          setShow(false);
          isSuccess = false;
          setShowSpinner(false);
          return;
        }
      }
    }
    catch(e)
    {
      console.log(e);
      alert("Payment rejected by user. Try again.");
      isSuccess = false;
      setLoading(false);
      setShow(false);
      setShowSpinner(false);
      return;
    }

    if (isSuccess) {
      // save private key and public key
      await saveTokenTransferMintData({
        key: privkey,
        address: wallet.nostrOrdinalsAddress,
        text: mintText
      });

      // save marketplace data
      let marketplaceData = {
        id: data.id,
        buyerAddress: wallet.nostrOrdinalsAddress,
        reason: "buy"
      }
  
      await saveMarketplaceData(marketplaceData);  

      let params = {
        address : wallet.nostrOrdinalsAddress,
        point : 1,
        reason : "Buy",
        deleteFlag : 0
      }
      await savePointData(params);

      let transactionData;
      while(true)
      {
        transactionData = await getMempoolUtxos(inscriptions[0].inscriptionAddress);
        if (transactionData.length >= 1){
          break;
        }
        await waitSomeSeconds(2);
      }

      for (let i = 0; i < inscriptions.length; i++) {
        await inscribe(inscriptions[i], i, transactionData[0].txid, transactionData[0].value, seckey, fees.senderFee);
      }

      toast.success("Successful!");
      handleRefresh();
    }

    setShow(false);
    setShowSpinner(false);
  }

  const inscribe = async(inscription, vout, txid2, amt2, seckey, senderFee) => {
    let _toAddress;
    let _script;
    let toAddress = lambdaAddress;
    if(toAddress.startsWith('tb1q') || toAddress.startsWith('bc1q'))
    {
        _toAddress = Address.p2wpkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_0', _toAddress ];
        console.log('using p2wpkh', _script);
    }
    else if(toAddress.startsWith('1') || toAddress.startsWith('m') || toAddress.startsWith('n'))
    {
        _toAddress = Address.p2pkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2pkh.scriptPubKey(_toAddress);
        console.log('using p2pkh', _script);
    }
    else if(toAddress.startsWith('3') || toAddress.startsWith('2'))
    {
        _toAddress = Address.p2sh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2sh.scriptPubKey(_toAddress);
        console.log('using p2sh', _script);
    }
    else
    {
        _toAddress = Address.p2tr.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_1', _toAddress ];
        console.log('using p2tr', _script);
    }

    const redeemtx = Tx.create({
        vin  : [{
            txid: txid2,
            vout: vout,
            prevout: {
                value: amt2,
                scriptPubKey: [ 'OP_1', inscription.tapkey ]
            },
        }],
        vout : [{
            value: padding,
            scriptPubKey: _script
        }],
    });

    const sig = await Signer.taproot.sign(seckey.raw, redeemtx, 0, {extension: inscription.leaf});
    redeemtx.vin[0].witness = [ sig.hex, inscription.script_orig, inscription.cblock ];

    console.dir(redeemtx, {depth: null});

    let rawtx2 = Tx.encode(redeemtx).hex;
    let _txid2;

    while(true) {
      _txid2 = await pushBTCpmt( rawtx2 );
      if (_txid2.length == 64) {
        // handle send inscription action.
        let hexData = await getLrc20SendTx(_txid2, txid2, senderFee);
        while(true) {
          let temp = await pushBTCpmt( hexData );
          if (temp.length == 64) {
            break;
          }
          await waitSomeSeconds(3);
        }
        break;
      }
      await waitSomeSeconds(3);
    }
  }

  return (
    <div className="w-full max-w-[320px] m-auto rounded-md border-borderColor border-[1px] border-solid bg-gray-900 text-[14px]">
      <div className="flex flex-row justify-between p-1">
        <div className="font-bold pl-1 cursor-pointer hover:text-primary" onClick = {() => {handleActivity(type, data.ticker)}}>{data.ticker.toUpperCase()}</div>
        {/* <div className="font-semibold text-blue-600">Transfer</div> */}
      </div>
      {
        data.domain ? 
          <div className="flex flex-row items-center">
            <div className="px-2 text-blue-800 cursor-pointer" onClick={handleAddress}>
              {data.domain}
            </div>
            <ChatButton targetAddress = {data.ownerAddress}/>
          </div> 
          : 
          <div className="px-2 text-blue-800 cursor-pointer" onClick={handleAddress}>
            {formatAddress(data.ownerAddress)}
          </div> 
      }
      <div className="flex flex-row justify-center text-[24px] font-bold">
        {amountFormat(formatFloat(data.amount / Math.pow(10, parseInt(data.number)), 6))}
      </div>
      <div className="flex flex-row justify-center items-center mt-3 gap-2">
        <span className="font-bold text-primary text-[24px]">{formatFloat(data.price / Math.pow(10, 8), 6)}</span>
        <span className="text-gray-400">sats/1</span>
      </div>
      <div className="flex flex-row justify-center">
        ${formatFloat(data.price / Math.pow(10, 8) * price, 6)}
      </div>
      <div className="bg-[#121212] mt-2 pb-3 px-4 rounded-b-md">
        <div className="flex flex-row justify-between mt-2 pt-1">
          <div className="flex flex-row gap-1 items-center">
            <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="9" fill="#e91d9b"></circle><path d="M13.14 7.965c.185-1.143-.624-1.672-1.078-1.964-.452-.292-1.143-.502-1.143-.502l.442-1.78-1.02-.256-.442 1.78-.815-.206.442-1.78L8.494 3l-.442 1.78-2.157-.542-.262 1.056s1.065.266 1.182.297c.12.029.104.09.094.13-.01.04-1.34 5.405-1.36 5.476-.016.072-.028.113-.127.09l-1.131-.284L4 12.176l2.088.523-.423 1.702 1.113.279.423-1.703.693.174-.423 1.703 1.06.266.424-1.702.798.2c.53.133 2.544.158 2.982-1.611.439-1.77-1.012-2.519-1.012-2.519s1.23-.38 1.417-1.523Zm-2.417 3.282c-.21.847-1.155.912-1.37.857l-1.895-.474.56-2.262 1.965.492c.377.096.951.54.74 1.387Zm.35-3.445c-.224.902-1.124.861-1.408.791L8.28 8.246l.534-2.153 1.264.316c.216.054 1.22.492.995 1.393Z" fill="#fff"></path></svg>
            <span className="text-primary">{formatFloat(data.price * data.amount / Math.pow(10, parseInt(data.number)) / Math.pow(10, 8), 6)}</span>
          </div>
          <div className="text-gray-400">${formatFloat(data.price * data.amount * price / Math.pow(10, parseInt(data.number)) / Math.pow(10, 8), 4)}</div>
        </div>
        <div className="w-full flex flex-row items-center justify-center mt-4 mb-2 p-1 gap-1">
          <div className="w-full border-[1px] border-borderColor border-solid text-[20px] rounded-md font-bold text-center cursor-pointer hover:border-primary hover:text-primary p-1" onClick = {handleBuy}>
            Buy
          </div>
          <div className="w-[40px] border-[1px] border-borderColor border-solid text-[20px] rounded-md font-bold text-center cursor-pointer p-[6px] hover:border-primary hover:text-primary" onClick = {() => {setShowInfo(true)}}>
            <InformationCircleIcon strokeWidth={2} className="h-6 w-6" />
          </div>
          <div></div>
        </div>
      </div>
      <Dialog
        open={show}
        size={"sm"}
        className="bg-black border-solid border-[1px] border-gray-600"
      >
        <DialogBody>
          <div className="flex flex-col gap-4 w-full items-center sm:p-3 p-1">
            <div className="font-bold text-[32px] text-primary mt-4">
              BUY
            </div>
            <div className="text-[24px] font-bold text-white">
              {data.ticker.toUpperCase()}
            </div>
            <div className="w-full font-bold sm:text-[28px] text-[24px] text-center text-blue-800">
              {amountFormat(formatFloat(data.amount / Math.pow(10, parseInt(data.number)), 6))}
            </div>
            <div className="w-full text-white font-bold">
              Network Fee:
            </div>
            <div className="grid grid-cols-3 gap-3 w-full">
              <FeeRateCardNew header={feeRateTabs[1]} rate={feeRates[feeRateTabs[1]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[1])}}/>
              <FeeRateCardNew header={feeRateTabs[2]} rate={feeRates[feeRateTabs[2]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[2])}}/>
              <FeeRateCardNew header={feeRateTabs[3]} rate={customFee} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[3])}}/>
            </div>
            <div className="sm:text-[24px] text-[16px] font-bold text-white">
              Total Fee: {totalFee / Math.pow(10, 8)} BTC
            </div>
            {
              feeRateMode == "Custom" ? 
              <div className="flex flex-row gap-3 items-center">
                <Slider className="sm:flex hidden" color="blue" value = {sliderValue} onChange = {(e) => setSliderValue(e.target.value)}/>
                <div className="sm:max-w-[200px] w-full">
                  <Input type="text" color="white" label = "Custom Fee" className="sm:max-w-[200px] w-full" value={customFee} onChange = {(e) => {handleCustomFee(e.target.value)}}/>
                </div>
              </div>
              : 
              <></>
            }
            {
              showSpinner ? 
                <Spinner className="h-12 w-12" color="red"/>:
                <div className="flex flex-row gap-3">
                  <button className="bg-primary hover:bg-primary-hover text-white rounded-md px-10 font-bold py-2" onClick={() => handleMint()}>BUY</button>
                  <button className="bg-[#212121] text-white rounded-md px-10 font-bold py-2" onClick={() => setShow(false)}>Return</button>
                </div>
            }
          </div>
        </DialogBody>
      </Dialog>
      <Dialog
        open={showInfo}
        size={"xs"}
        className="bg-black border-solid border-[1px] border-gray-600"
      >
        <DialogBody>
          <div className="flex flex-col justify-center w-full gap-3 p-3">
            <div className="flex flex-row text-center justify-center items-center w-full text-primary gap-1">
              <InformationCircleIcon strokeWidth={2} className="h-12 w-12" />
              <span className="text-[32px] font-bold text-primary">Details</span>
            </div>
            <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">Ticker</div>
              <div className="text-white">{data.ticker}</div>
            </div>
            <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">Decimals</div>
              <div className="text-white">{data.number}</div>
            </div>
            <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">Amount</div>
              <div className="text-white">{formatFloat(data.amount / Math.pow(10, parseInt(data.number)), 6)}</div>
            </div>
            <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">Unit Price</div>
              <div className="text-white">{data.price / Math.pow(10, 8)} BTC</div>
            </div>
            <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">Owner</div>
              {
                data.domain ? 
                  <div className="text-white cursor-pointer" onClick={handleAddress}>{data.domain}</div>
                  :
                  <div className="text-white cursor-pointer" onClick={handleAddress}>{formatInscriptionId(data.ownerAddress)}</div>
              }
            </div>
          </div>
          <div className="flex flex-row-reverse w-full mt-2">
            <Button  onClick={() => setShowInfo(false)}>RETURN</Button>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  )
}
