import React from 'react'
import { getLrc20SendTx } from '../util/new-api';
import { getBtcUtxos } from '../util/ordinal';
import { postUnisatTransaction } from '../util/api';
import { prepareSrc20Wallet } from '../util/stamp-api';

export default function Test() {
  const handleClick = async () => {
    try {
      // let res = await getBtcUtxos("bc1qx3qjlt56cvmqvnf6e25g4xw5xmaw06n47dhn50");
      // let utxos = [];
      // for(let temp of res) {
      //   if (temp.satoshis >=1000) {
      //     utxos.push({
      //       txid: temp.txid,
      //       vout: temp.vout,
      //       value: temp.satoshis
      //     })  
      //   }
      // }

      // let params = {
      //   utxos: utxos,
      //   count: 2,
      //   address: "bc1qx3qjlt56cvmqvnf6e25g4xw5xmaw06n47dhn50",
      //   output: 546,
      //   feerate: 11
      // }

      // let tempRes = await prepareSrc20Wallet(params);
      // const signedPsbt = await window.unisat.signPsbt(tempRes.psbt);
      // const txid = await window.unisat.pushPsbt(signedPsbt);
      // console.log(params);
      // console.log(tempRes);

      const signedPsbt = await window.unisat.signPsbt("cHNidP8BAP2HAQIAAAAE7bAFIItZqEJssBMEmXxSdqJw8ZgnPr5rReNXzjkygrsBAAAAAP/////tsAUgi1moQmywEwSZfFJ2onDxmCc+vmtF41fOOTKCuwAAAAAA/////19VCA87wX75ChKpRwJiBa9vcy4tQnxjAkC/pNllkldiAAAAAAD/////7bAFIItZqEJssBMEmXxSdqJw8ZgnPr5rReNXzjkygrsCAAAAAP////8HsAQAAAAAAAAWABTqk5jLWxgxoiEUsTYSlwo9Ba5VZyICAAAAAAAAFgAU6pOYy1sYMaIhFLE2EpcKPQWuVWdwFwAAAAAAABYAFDRBL66awzYGTTrKqIqZ1Db65+p1WAIAAAAAAAAWABTqk5jLWxgxoiEUsTYSlwo9Ba5VZ1gCAAAAAAAAFgAU6pOYy1sYMaIhFLE2EpcKPQWuVWfQBwAAAAAAABYAFOqTmMtbGDGiIRSxNhKXCj0FrlVnhwYAAAAAAAAWABTqk5jLWxgxoiEUsTYSlwo9Ba5VZwAAAAAAAQEfWAIAAAAAAAAWABTqk5jLWxgxoiEUsTYSlwo9Ba5VZwEDBAEAAAAAAQEfWAIAAAAAAAAWABTqk5jLWxgxoiEUsTYSlwo9Ba5VZwEDBAEAAAAAAQEfIgIAAAAAAAAWABQ0QS+umsM2Bk06yqiKmdQ2+ufqdQEIbAJIMEUCIQCuUT/YN2IIKowF6tJX2qtPCErE+qDZKN3L9e1oTy1g+QIgBwED8betoYG+zjFYDTiv+ZEG0xV6rS86m5zmhbbOv3qDIQIgl2uwV4Cdnx7HsDO1Mji4CQHOj4vWfy+jm87FcJzxuwABAR/+PAAAAAAAABYAFOqTmMtbGDGiIRSxNhKXCj0FrlVnAQMEAQAAAAAAAAAAAAAA");
      console.log("--------------", signedPsbt)
      const txid = await window.unisat.pushPsbt(signedPsbt);
      // let hex = "020000000001016c71fdb64a6ad10de234190efb8b4b043ec63d43360c5297cff5b83d66df94590000000000ffffffff0200000000000000000a6a5d0714eba63514bd012202000000000000225120569efa2c50b797dd545ceb685fa8aeb055cf4c28e8e103ea9dcd132a27487d6602483045022100d1694aab1b7eecfcfe371aaa4ea18f7226aac6acf843fb2f43da38d67000b29a0220236da3952be13b25e45e17c6f14d2f359765d4e4afd90809516bcc75089b3e5e0121030929c18521f88f2e6d97fceadb1ea1e3bcb0f6abfbd70c83d90537e8e197259f00000000";
      // await postUnisatTransaction(hex);

    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div className = "mt-[80px] text-white">
      <button onClick={handleClick}>Connect Wallet</button>
    </div>
  )
}
