import React, {useState, useEffect, useCallback} from 'react'
import {Spinner} from '@material-tailwind/react'
import { debounce } from 'lodash';
import toast, { Toaster } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import { encodedAddressPrefix, tippingAddress, ownerAddress, royalty, tip, contentLength, serviceFeeRate, padding } from '../configs/constant';
import { Input } from '@material-tailwind/react'
import { formatAddress } from '../util/format-data'
import { registerRunesPayment, latestTokenNew, checkWhiteListedAddress, registerTokenNew, getPaymentTx, getPaymentUtxosRunes, getPaymentHistoryNew, savePaymentHistoryNew, getSignMintTextNew, getMintStatus, checkWhiteListedDomain } from '../util/new-api'
import { getAsciiSum } from '../util/format-data';
import { getFeeRate, bytesToHex, buf2hex, textToHex, hexToBytes, getMempoolUtxos, waitSomeSeconds, pushBTCpmt } from '../util/inscribe-util';
import FeeRateCard from '../components/FeeRateCard';
import { BitcoinNetworkType, signMessage, signTransaction, sendBtcTransaction } from 'sats-connect';
import { latestDmtTokenRandom, latestTokenRandom, checkRuneName, mintRuneStart, mintRuneCancel, mintRunePrepare } from '../util/new-api';
import RuneTopIndexer from '../components/Rune/RuneTopIndexer';
import { postUnisatTransaction } from '../util/api';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Slider,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@material-tailwind/react";

import { useWallet, useWallets } from '@wallet-standard/react';
import { savePointData } from '../util/api';

export default function MintRunes() {
  const { wallets } = useWallets();

  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const wallet = useSelector(state => state.wallet);

  const { Address, Script, Signer, Tap, Tx } = window.tapscript;
  const feeRateTabs = ["Slow", "Normal", "Fast", "Custom"];

  const [feeRateMode, setFeeRateMode] = useState("Normal");
  const [feerate, setFeerate] = useState(0);
  const [feeRates, setFeeRates] = useState({});
  const [feeValues, setFeeValues] = useState({
    "inscriptionFee": 0,
    "networkFee": 0,
    "serviceFee": 0,
    "royaltyFee": 0,
    "totalFee": 0
  });
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState('');
  const [amount, setAmount] = useState(1);
  const [maximum, setMaximum] = useState(10000);
  const [sum, setSum] = useState(0);
  const [deployLimit, setDeployLimit] = useState(10000);
  const [address, setAddress] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [key, setKey] = useState('');
  const [customFee, setCustomFee] = useState(0);
  const [sliderValue, setSliderValue] = useState(2);
  const [show, setShow] = useState(false);
  const [runeId, setRuneId] = useState("");
  const [inscriptionStatus, setInscriptionStatus] = useState(false);
  const [isRoyalty, setIsRoyalty] = useState(1);

  useEffect(() => {
    let intervalId;

    const updateFees = async () => {
      try {
        if (feeRateMode == "Custom") {
          setFeerate(customFee);
          setFeeValues(calculateFeeReal(customFee, amount));
        }
        else
        {
          let response = await getFeeRate();
          setFeeRates(response);
          setFeerate(response[feeRateMode]);
          setFeeValues(calculateFeeReal(response[feeRateMode], amount));
          if (customFee == 0) 
          {
            setCustomFee(response["Fast"] + 1);
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    }
    updateFees();
    intervalId = setInterval(updateFees, 10 * 1000);
    return () => {
      clearInterval(intervalId);
    }
  }, [feeRateMode, amount, customFee, isRoyalty])

  useEffect(() => {
    let value = (sliderValue / 100) * (500 - feeRates["Normal"]) + feeRates["Normal"];
    setCustomFee(Math.floor(value));
  }, [sliderValue])

  useEffect(() => { 
    setAddress(wallet.nostrOrdinalsAddress);
    handleCheckRoyalty(wallet.nostrOrdinalsAddress);
  }, [wallet.nostrOrdinalsAddress]);

  const handleCheckRoyalty = async (targetAddress) => {
    if (targetAddress != "") {
      let result = await latestDmtTokenRandom(targetAddress, 32);
      if (result.total > 0) {
        setIsRoyalty(0);
        return;
      }

      result = await latestTokenRandom(targetAddress, 58);
      if (result.total > 0 ){
        setIsRoyalty(0);
        return;
      }
    }

    setIsRoyalty(1);
  }

  const debouncedSearch = useCallback(debounce((value) => {
    setPage(1);
    updateTokens(value, 1)
  }, 300), []); 

  const handleSearch = (value) => {
    setKey(value);
    debouncedSearch(value);
  };

  const handleMint = async () => {
    setLoading(true);

    if (wallet.nostrOrdinalsAddress == "")
    {
      toast.error("Please connect your wallet!");
      setLoading(false);
      return;
    }

    if (address == "") {
      toast.error("Please insert inscription address!");
      setLoading(false);
      return;
    }

    if (amount <= 0 ) {
      toast.error("Please insert amount!");
      setLoading(false);
      return;
    }

    if (runeId == "") {
      toast.error("Please insert Rune / Rune Id!");
      setLoading(false);
      return;
    }
    let runesResult = await checkRuneName(runeId);

    if (runesResult.status == "fail") {
      toast.error("Please insert valid Rune / Rune Id!");
      setLoading(false);
      return;
    }

    let runekey = runesResult.runekey;

    setLoading(false);
    setShow(true);
    setInscriptionStatus(false);
    
    const results = await getPaymentHistoryNew();
    const toggleValue = results[0].value;
    let fundingAddress = '';

    if (toggleValue == 0)
      fundingAddress = tippingAddress;
    else
      fundingAddress = ownerAddress;

    let isSuccess = true;

    const fundResults = await mintRunePrepare(runekey, amount, address, 546, feerate);

    let inscriptionAddressList = [];
    let inscriptionFee = [];

    for(let temp of fundResults.data)
    {
      inscriptionAddressList.push(temp.fundAddress);
      inscriptionFee.push(temp.fee);
    }

    let tempfeerate = parseFloat(feerate);
    if (wallet.domain == "xverseWallet") tempfeerate = Math.ceil(feerate * 1.2);
    const paymentUtxos = await getPaymentUtxosRunes(wallet.nostrPaymentAddress, inscriptionAddressList, inscriptionFee, fundingAddress, isRoyalty * 10000, wallet.paymentPublicKey, tempfeerate, fundResults.data.length, wallet.domain)

    if (paymentUtxos.status == "fail") {
      alert("Insufficient balance.");
      setLoading(false);
      setShow(false);
      isSuccess = false;
      await mintRuneCancel(fundResults.randomKey);
      return;
    }

    try{
      if (wallet.domain == "tapwallet") {
        const signedPsbt = await window.tapwallet.signPsbt(paymentUtxos.psbt);
        const txid = await window.tapwallet.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "unisat") {
        const signedPsbt = await window.unisat.signPsbt(paymentUtxos.psbt);
        const txid = await window.unisat.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "okxwallet") {
        const signedPsbt = await window.okxwallet.bitcoin.signPsbt(paymentUtxos.psbt);
        const txid = await window.okxwallet.bitcoin.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "xverseWallet") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          await signTransaction({
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
            },
            onCancel: async () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                setShow(false);
                await mintRuneCancel(fundResults.randomKey);
            },
          });
  
        }
        else {
          alert("Insufficient balance.");
          setLoading(false);
          setShow(false);
          isSuccess = false;
          await mintRuneCancel(fundResults.randomKey);
          return;
        }
      }
      if (wallet.domain == "magiceden") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

          await signTransaction({
            getProvider: async () =>
              magicedenWallets[0].features['sats-connect:'].provider,
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {   
            },
            onCancel: async () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                setShow(false);
                await mintRuneCancel(fundResults.randomKey);
            },
          });
  
        }
        else {
          alert("Insufficient balance.");
          setLoading(false);
          setShow(false);
          isSuccess = false;
          await mintRuneCancel(fundResults.randomKey);
          return;
        }
      }
    }
    catch(e)
    {
      console.log(e);
      alert("Payment rejected by user. Try again.");
      isSuccess = false;
      setLoading(false);
      setShow(false);
      await mintRuneCancel(fundResults.randomKey);
      return;
    }

    if (isSuccess) {
      let transactionData;
      while(true)
      {
        transactionData = await getMempoolUtxos(inscriptionAddressList[0]);
        if ( transactionData != undefined && transactionData.length >= 1){
          break;
        }
        await waitSomeSeconds(3);
      }

      let paramDatas = [];
      for(let i = 0; i < inscriptionAddressList.length; i++) {
        paramDatas.push({
          txid : transactionData[0].txid,
          vout : i,
          value : inscriptionFee[i],
          fundAddress : inscriptionAddressList[i]
        })
      }

      while(true) {
        if (paramDatas.length == 0) break;
        let resDatas = await mintRuneStart(fundResults.randomKey, paramDatas);
        paramDatas = [];
        for(let resData of resDatas) {
          let txid = "";
          while(true) {
            //txid = await pushBTCpmt(resData.hex);
            await waitSomeSeconds(3);
            txid = await postUnisatTransaction(resData.hex);
            if (txid.length == 64) break;
            await waitSomeSeconds(10);
            txid = await pushBTCpmt(resData.hex);
            if (txid.length == 64) break;
          }
          if (resData.value > 0) {
            paramDatas.push({
              txid : txid,
              vout : 1,
              value : resData.value,
              fundAddress : resData.fundAddress
            })
          }
        }
      }

      await savePaymentHistoryNew(1 - toggleValue);
      setLoading(false);
      setInscriptionStatus(true);
    }
  }

  const mintRunes = async ( isSuccess, privkey, address, _fundingAddress, init_script, init_cblock, init_leaf, runekey, toggleValue, init_tapkey, seckey ) => {
    if (isSuccess) {
      // save private key and public key
      await registerRunesPayment(privkey, address, runekey);

      let transactionData;
      while(true)
      {
        transactionData = await getMempoolUtxos(_fundingAddress);
        if (transactionData.length >= 1){
          break;
        }
        await waitSomeSeconds(2);
      }

      let _toAddress;
      let _script;
      let toAddress = address;
      if(toAddress.startsWith('tb1q') || toAddress.startsWith('bc1q'))
      {
          _toAddress = Address.p2wpkh.decode(toAddress, encodedAddressPrefix).hex;
          _script = [ 'OP_0', _toAddress ];
          console.log('using p2wpkh', _script);
      }
      else if(toAddress.startsWith('1') || toAddress.startsWith('m') || toAddress.startsWith('n'))
      {
          _toAddress = Address.p2pkh.decode(toAddress, encodedAddressPrefix).hex;
          _script = Address.p2pkh.scriptPubKey(_toAddress);
          console.log('using p2pkh', _script);
      }
      else if(toAddress.startsWith('3') || toAddress.startsWith('2'))
      {
          _toAddress = Address.p2sh.decode(toAddress, encodedAddressPrefix).hex;
          _script = Address.p2sh.scriptPubKey(_toAddress);
          console.log('using p2sh', _script);
      }
      else
      {
          _toAddress = Address.p2tr.decode(toAddress, encodedAddressPrefix).hex;
          _script = [ 'OP_1', _toAddress ];
          console.log('using p2tr', _script);
      }

      for(let tempData of transactionData) {
        const init_redeemtx = Tx.create({
          vin  : [{
              txid: tempData.txid,
              vout: tempData.vout,
              prevout: {
                  value: tempData.value,
                  scriptPubKey: [ 'OP_1', init_tapkey ]
              },
          }],
          vout : [
            {
              value: 0,
              scriptPubKey: [ 'OP_RETURN', 'OP_13', runekey ]
            },
            {
              value: 546,
              scriptPubKey: _script
            }
          ]
        })
    
        const init_sig = await Signer.taproot.sign(seckey.raw, init_redeemtx, 0, {extension: init_leaf});
        init_redeemtx.vin[0].witness = [ init_sig.hex, init_script, init_cblock ];
    
        let rawtx = Tx.encode(init_redeemtx).hex;
        let _txid = await pushBTCpmt(rawtx);

        await sleep(2000);
      }
    }
    
    await savePaymentHistoryNew(1 - toggleValue);

    setLoading(false);
    setInscriptionStatus(true);
  }

  const calculateFeeReal = (fee, amount) => {

    const baseTxSize = 10;
    const inSize = 57.5;
    const outSize = 43;
    const txSize = baseTxSize + inSize * 1 + outSize * 2;

    let mintFee = Math.floor(fee * txSize) + 546;
    let networkFee = mintFee * amount;
  
    let royaltyFee = 10000 * isRoyalty;
    let totalFee = networkFee + royaltyFee;
    return {
      "mintFee": mintFee,
      "networkFee": networkFee,
      "royaltyFee": royaltyFee,
      "totalFee": totalFee
    }
  }

  const handleRuneId = (value) => {
    console.log(value);
    if (typeof value === 'string') {
      value = value.replace(/ /g, '•');
      setRuneId(value.toUpperCase());
    }
    else {
      setRuneId(value.toUpperCase())
    }
  };
  
  const handleCustomFee = (value) => {
    const sanitizedValue = value.replace(/[^0-9.]/g, '');
    setCustomFee(sanitizedValue);
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return (
    <div className="text-white mt-[65px] w-full max-w-[1500px] md:px-7 px-2 flex flex-col justify-center items-center mb-10 min-h-[600px]">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed" color="pink"/> : <></>
      }
      <div className="text-white font-semibold sm:text-[30px] text-[24px] mt-[80px] text-center bg-primary py-1 px-4 rounded-md font-title">MINT RUNES</div>
      <div className="flex md:flex-row flex-col gap-3 items-start ">
        <RuneTopIndexer handle = {(value) => {handleRuneId(value)} }/>
        <div className="md:min-w-[500px] min-w-[300px] border-gray-800 border-[1px] border-solid p-8 rounded-lg mt-5 flex flex-col gap-8 bg-[#081817] md:w-[600px] w-full md:mt-[160px]">
          <div>
            <Input type="text" color="white" label = "Rune Ticker OR Rune ID" className="w-full md:min-w-[400px] min-w-[250px]" value={runeId} onChange = {(e) => {handleRuneId(e.target.value)}}/>
          </div>
          <div>
            <Input type="text" color="white" label = "Receiver Address" className="w-full md:min-w-[400px] min-w-[250px]" value={address} onChange = {(e) => {setAddress(e.target.value)}}/>
          </div>
          <div>
            <Input type="text" color="white" label = "Repeat Mint Amount" className="w-full md:min-w-[400px] min-w-[250px]" value={amount} onChange = {(e) => {setAmount(e.target.value)}}/>
          </div>
          <div className="sm:text-[18px] text-[14px]">
            Select the network fee you want to pay:
          </div>
          <div className="grid grid-cols-3 gap-3">
            <FeeRateCard header={feeRateTabs[1]} rate={feeRates[feeRateTabs[1]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[1])}}/>
            <FeeRateCard header={feeRateTabs[2]} rate={feeRates[feeRateTabs[2]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[2])}}/>
            <FeeRateCard header={feeRateTabs[3]} rate={customFee} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[3])}}/>
          </div>
          {
            feeRateMode == "Custom" ? 
            <div className="flex flex-row gap-3 items-center">
              <Slider className="sm:flex hidden" color="blue" value = {sliderValue} onChange = {(e) => setSliderValue(e.target.value)}/>
              <div className="sm:max-w-[200px] w-full">
                <Input type="text" color="white" label = "Custom Fee" className="sm:max-w-[200px] w-full" value={customFee} onChange = {(e) => {handleCustomFee(e.target.value)}}/>
              </div>
            </div>
            : 
            <></>
          }
          {/* <div className="m-auto md:w-[400px] w-[300px]">
            <div className="flex flex-row justify-between mt-1 sm:text-[20px] text-[16px]">
              <div className="font-bold">Total Cost</div>
              <div className="text-right font-bold text-blue-600">{feeValues["totalFee"] / Math.pow(10, 8)} BTC</div>
            </div> 
          </div> */}
          <div className="flex flex-row w-full justify-center">
            <button className="bg-primary hover:bg-primary-hover rounded-md py-2 min-w-[240px]" onClick={handleMint}>Mint</button>
          </div>
        </div>
      </div>
      <Dialog
        open={show}
        size={"xs"}
      >
        <DialogHeader>
          {
            inscriptionStatus ? 
              <div className="flex flex-row w-full justify-center items-center gap-3 mt-6 sm:text-[32px] text-[24px] font-bold text-primary">
                <CheckCircleIcon strokeWidth={2} className="h-16 w-16" /> <span>Mint successful.</span>
              </div>
              :
              <div className="flex flex-row w-full justify-center mt-6 sm:text-[32px] text-[24px] font-bold text-primary">
                Minting Now
              </div>
          }
        </DialogHeader>
        <DialogBody>
          <div className={`flex flex-col gap-3 w-full ${inscriptionStatus ? 'min-h-[100px]' : 'min-h-[160px]'}`}>
            {
              inscriptionStatus ? 
              <div className="flex flex-col items-center gap-4">
                {/* <div className="flex flex-row justify-center w-full text-[20px] px-2 font-semibold text-green-600 text-center">
                  <CheckCircleIcon strokeWidth={2} className="h-24 w-24" />
                </div> */}
                <div className="w-[180px] mt-5">
                  <Button
                    onClick={() => {
                      setShow(false);
                      setInscriptionStatus(false);
                    }}
                    fullWidth
                  >Close</Button>
                </div>
              </div>
              :
              <div className="flex flex-col items-center gap-5">
                <div className="flex flex-row justify-center w-full font-semibold text-[#616161] sm:text-[20px] text-[14px] px-6 text-center">
                  Don´t close this window before the transaction is complete.
                </div>
                <Spinner className="h-12 w-12" />
              </div>
            }
          </div>
        </DialogBody>
      </Dialog>
    </div>
  )
}
