import React, {useState, useEffect, useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Brc20 from '../components/Explorer/Brc20'
import Dmt from '../components/Explorer/Dmt'
import Tap from '../components/Explorer/Tap'
import Unat from '../components/Explorer/Unat'
import Rune from '../components/Explorer/Rune'
import Src20 from '../components/Explorer/Src20';
import Stamp from '../components/Explorer/Stamp';
import Inscription from '../components/Explorer/Inscription';
import Names from '../components/Explorer/Names';
import { formatAddress } from '../util/format-data';
import toast, { Toaster } from 'react-hot-toast';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { debounce } from 'lodash';
import { latestDomain, getDomainAddress } from '../util/api';
import { 
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
  Button
} from '@material-tailwind/react';

import {
  Routes,
  Route,
  useNavigate,
  Link
} from "react-router-dom";
import { updateKey } from '../store/action';
import Activity from '../components/Explorer/Activity';
import { getPointData } from '../util/api';
import ChatButton from '../components/Chat/ChatButton';
import Lrc20 from '../components/Explorer/Lrc20';
import Bitmap from '../components/Explorer/Bitmap';
import Dropdown from '../components/Dropdown/index';
import Arc20 from '../components/Explorer/Arc20';

export default function Explorer() {
  const dispatch = useDispatch();
  const keyAddress = useSelector(state => state.key);
  const navigate = useNavigate();
  const [key, setKey] = useState(keyAddress.domain ? keyAddress.domain : keyAddress.address);
  const [point, setPoint] = useState(0);
  const [tab, setTab] = useState(0) // 0: brc20, 1: tap, 2: dmt, 3: unat
  const [activeDropDown, setActiveDropDown] = useState("ALL");

  const dropdownTabs = ["ALL", "BRC-20", "RUNES", "TAP", "DMT", "UNAT", "BITMAP", "LRC-20", "SRC-20", "STAMPS", "ARC-20","NAMES"];

  useEffect(() => {
    switch (activeDropDown) {
      case "ALL":
        setTab(0);
        break;
      case "BRC-20":
        setTab(1);
        break;
      case "RUNES":
        setTab(5);
        break;
      case "TAP":
        setTab(2);
        break;
      case "DMT":
        setTab(3);
        break;
      case "UNAT":
        setTab(4);
        break;
      case "BITMAP":
        setTab(11);
        break;
      case "LRC-20":
        setTab(10);
        break;
      case "SRC-20":
        setTab(7);
        break;
      case "STAMPS":
        setTab(8);
        break;
      case "NAMES":
        setTab(9);
        break;
      case "ARC-20":
        setTab(12);
        break;
    }
  }, [activeDropDown])

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        toast.success('Address copied to clipboard!')
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  useEffect(() => {
    const fetchPoints = async () => {
      if (keyAddress.address != "" && keyAddress.address != undefined) {
        let result = await getPointData(
          {
            address: keyAddress.address
          }
        )
        if (result.total < 0) 
          setPoint(0);
        else
          setPoint(result.total ?? 0);
      }
      else setPoint(0);
    }
    fetchPoints(0);
  }, [keyAddress.address])

  const debouncedSearch = useCallback(debounce( async (value) => {
    let result = await getDomainAddress(value);
    let domain = "";
    let address = "";
    
    if (result.total == 0) 
      address = value;
    else
    {
      domain = result.domain;
      address = result.address;
    }

    let keyData = {address: address, domain: domain};
    dispatch(updateKey(keyData));
  }, 800), []); 

  const handleKeyChange = (e) => {
    setKey(e.target.value)
    debouncedSearch(e.target.value)
  }

  const handleMint = () => {
    navigate('/register');
  }
  
  return (
    <div className="text-white mt-[65px] w-full md:px-7 px-2 flex flex-col items-center mb-10 min-h-[600px]">
      <div className="flex flex-row w-full mt-3">
        <Input type="text" color="white" label = "Input Name or Address" className="w-full min-w-[300px]" value={key} onChange = {handleKeyChange}/>
      </div>
      <div className="mt-4 w-full">
        {
          keyAddress.address == "" ? <></> :
          <div className="flex flex-col gap-1">
            <div className = "flex flex-row items-center gap-2">
              <div className="md:text-[32px] text-[24px] font-semibold">{keyAddress.domain ? keyAddress.domain : formatAddress(keyAddress.address)}</div>
              {
                keyAddress.domain ? <ChatButton targetAddress={keyAddress.address}/>
                :
                <div className="flex flex-row items-center bg-primary px-2 py-1 rounded-md ml-3 cursor-pointer" onClick = {handleMint}>Register</div>
              }
              <div className="flex flex-row items-center bg-primary px-2 py-1 rounded-md cursor-pointer" onClick={() => {handleCopy(keyAddress.address)}}>
                <DocumentDuplicateIcon className="h-6 w-6 cursor-pointer" />
                <span>Copy</span>
              </div>
              {
                keyAddress.domain ? <div className="text-[24px] font-bold"><span className="text-white">&nbsp;Points:&nbsp;</span><span className="text-primary">{point}</span></div>
                :
                <div className="sm:flex hidden text-[24px] font-bold"><span className="text-white">&nbsp;Points:&nbsp;</span><span className="text-primary">{point}</span></div>
              }
            </div>
            <div className = "sm:hidden flex flex-row">
              {
                keyAddress.domain ? <></>
                :
                <div className="text-[24px] font-bold"><span className="text-white">Points:&nbsp;</span><span className="text-primary">{point}</span></div>
              }
            </div>
          </div>
        }
      </div>
      <div className="sm:flex hidden md:flex-row flex-wrap sm:gap-2 gap-1 w-full mt-5 font-title">
        <div className={`md:text-[18px] text-[14px] ${tab == 0 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(0)}>ALL</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 1 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(1)}>BRC-20</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 5 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(5)}>RUNES</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 2 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(2)}>TAP</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 3 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(3)}>DMT</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 4 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(4)}>UNAT</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 11 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(11)}>BITMAP</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 10 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(10)}>LRC-20</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 7 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(7)}>SRC-20</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 8 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(8)}>STAMPS</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 12 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(12)}>ARC-20</div>
        <div className={`md:text-[18px] text-[14px] ${tab == 9 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(9)}>NAMES</div>
        <div className={`md:text-[18px] text-[14px] px-2 py-1 bg-primary hover:bg-primary-hover cursor-pointer text-white rounded-md`} onClick={() => setTab(6)}>ACTIVITY</div>
      </div>
      <div className="sm:hidden flex flex-row gap-3 w-full items-center">
        <Dropdown tabs = {dropdownTabs} setActiveDropDown = {setActiveDropDown} activeDropDown = {activeDropDown}/>
        <div className={`text-[18px] w-full text-center px-2 py-2 bg-primary hover:bg-primary-hover cursor-pointer text-white rounded-md`} onClick={() => setTab(6)}>ACTIVITY</div>
      </div>
      {
        tab == 0 ? <Inscription keyAddress= {keyAddress.address} /> : <></>
      }
      {
        tab == 1 ? <Brc20 keyAddress= {keyAddress.address} /> : <></>
      }
      {
        tab == 2 ? <Tap keyAddress= {keyAddress.address} /> : <></>
      }
      {
        tab == 3 ? <Dmt keyAddress= {keyAddress.address} /> : <></>
      }
      {
        tab == 4 ? <Unat keyAddress= {keyAddress.address} /> : <></>
      }
      {
        tab == 5 ? <Rune keyAddress= {keyAddress.address} /> : <></>
      }
      {
        tab == 6 ? <Activity keyAddress= {keyAddress.address} /> : <></>
      }
      {
        tab == 7 ? <Src20 keyAddress= {keyAddress.address} /> : <></>
      }
      {
        tab == 8 ? <Stamp keyAddress= {keyAddress.address} /> : <></>
      }
      {
        tab == 9 ? <Names keyAddress= {keyAddress.address} /> : <></>
      }
      {
        tab == 10 ? <Lrc20 keyAddress= {keyAddress.address} /> : <></>
      }
      {
        tab == 11 ? <Bitmap keyAddress= {keyAddress.address} /> : <></>
      }
      {
        tab == 12 ? <Arc20 keyAddress= {keyAddress.address} /> : <></>
      }
    </div>
  )
}
