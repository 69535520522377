import React from 'react'
import arc20Img from '../../assets/imgs/atomical.jpg'

export default function Arc20Card(props) {
  const { data } = props;

  return (
    <div className="border-borderColor border-[1px] border-solid bg-gray-900 p-3 rounded-md">
      <img src={arc20Img} alt="no image"/>
      <div className="text-primary font-bold text-[20px]">{data.symbol}</div>
      <div>Amount: {data.holdingAmount}</div>
    </div>
  )
}
