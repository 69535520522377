import React from 'react'
import {useState, useEffect, useCallback} from 'react'
import { 
  Input,
  Spinner,
  IconButton,
  Typography,
  Progress,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Tooltip
} from '@material-tailwind/react';
import { ArrowPathIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, CircleStackIcon } from '@heroicons/react/24/outline';
import { FunnelIcon } from '@heroicons/react/24/outline';
import TapCard from '../Cards/TapCard';
import { getBtcPrice } from '../../util/api';
import { v4 as uuidv4 } from 'uuid';
import { getMarketplaceData } from '../../util/api';
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import {
  useNavigate,
  useLocation
} from "react-router-dom";

export default function DmtMarketplace({handleActivity}) {
  const wallet = useSelector(state => state.wallet);
  const navigate = useNavigate();

  const [price, setPrice] = useState(0);
  const [ticker, setTicker] = useState("");
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDir, setOrderDir] = useState("desc");
  const [datas, setDatas] = useState([]);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const fetchBtcPrice = async () => {
      let tempPrice = await getBtcPrice();
      setPrice(tempPrice);
    }
    fetchBtcPrice();
  }, [])

  useEffect(() => {
    fetchMarketplaceData(ticker);
  }, [page, orderBy, orderDir, refresh])

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const doublePrev = () => {
    setPage(1);
  }

  const doubleNext = () => {
    setPage(total);
  }

  const fetchMarketplaceData = async(value = ticker) => {
    setLoading(true);
    let params = {
      ticker: value,
      deleteFlag: 0,
      type: 1, // dmt
      reason: "sale",
      orderBy: orderBy,
      orderDir: orderDir,
      offset: page
    }
    let results = await getMarketplaceData(params);
    setDatas(results.data);
    setTotal(Math.ceil(results.total / limit))
    setLoading(false);
  }

  const debouncedSearch = useCallback(debounce( async (value) => {
    await fetchMarketplaceData(value);
  }, 800), []); 

  const handleTicker = (value) => {
    setTicker(value);
    if (value != "") {
      setOrderBy("price");
      setOrderDir("asc");
    }
    else {
      setOrderBy("id");
      setOrderDir("desc");
    }
    debouncedSearch(value);
  }

  const handleOrder = (value) => {
    switch (value) {
      case 1:
        setOrderBy("id");
        setOrderDir("desc");
        break;
      case 2:
        setOrderBy("id");
        setOrderDir("asc");
        break;
      case 3:
        setOrderBy("price");
        setOrderDir("desc");
        break;
      case 4:
        setOrderBy("price");
        setOrderDir("asc");
        break;
    }
  }

  const handleMyItemsClick = () => {
    if (wallet.nostrOrdinalsAddress == "") {
      toast.error("Please connect your wallet!");
      return;
    }
    navigate('/profile/3');
  }

  return (
    <div className="text-white w-full flex flex-col justify-center items-center mt-3">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      <div className="flex flex-row justify-end items-center gap-2 w-full">
        <div className="sm:flex hidden flex-row gap-2 items-center bg-primary px-4 py-[8px] rounded-md cursor-pointer hover:bg-primary-hover" onClick={() => handleMyItemsClick()}>
          <CircleStackIcon strokeWidth={2} className="h-5 w-5" />
          <span className="font-bold text-white">My Items</span>
        </div>
        <div><Input type="text" color="white" label = "Search by ticker" className="w-[250px]" value = {ticker} onChange = {(e) => {handleTicker(e.target.value)}}/></div>
        <Tooltip content="Refresh">
          <div className="border-borderColor border-solid border-[1px] rounded-md p-[6px] cursor-pointer" onClick={() => fetchMarketplaceData()}><ArrowPathIcon strokeWidth={2} className="h-6 w-6" /></div>
        </Tooltip>
        <Tooltip content="Filter">
          <Menu placement="bottom-end">
            <MenuHandler>
              <div className="border-borderColor border-solid border-[1px] rounded-md p-[6px] cursor-pointer"><FunnelIcon strokeWidth={2} className="h-6 w-6" /></div>
            </MenuHandler>
            <MenuList className="bg-black border-black text-white">
              <MenuItem onClick={() => handleOrder(1)}>Newest</MenuItem>
              <MenuItem onClick={() => handleOrder(2)}>Oldest</MenuItem>
              <MenuItem onClick={() => handleOrder(3)}>Highest Price</MenuItem>
              <MenuItem onClick={() => handleOrder(4)}>Lowest Price</MenuItem>
            </MenuList>
          </Menu>
        </Tooltip>
        <div className="sm:hidden flex">
          <Tooltip content="My Items">
            <div className="border-borderColor border-solid border-[1px] rounded-md p-[6px] cursor-pointer" onClick={() => handleMyItemsClick()}><CircleStackIcon strokeWidth={2} className="h-6 w-6" /></div>
          </Tooltip>
        </div>
      </div>
      <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 w-full mt-5">
        {
          datas != undefined ?
          datas.map((data) => 
            <TapCard key={uuidv4()} number = {data.number} amount = {data.amount} sats = {data.totalPrice / data.amount} price = {price} ticker={data.ticker} id={data.id} inscriptionId = {data.inscriptionId} actualPrice= {data.actualPrice} totalPrice={data.totalPrice} domain = {data.domain} ownerAddress = {data.ownerAddress} handleRefresh = {() => setRefresh(1 - refresh)} handleActivity = {handleActivity} type = {1}/>
          ):<></>
        }
      </div>
      {
        total > 1 ? 
        <div className="flex flex-row items-center gap-8 justify-end mt-5">
          <div className="flex flex-row items-center gap-2">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={doublePrev}
              disabled={page === 1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronDoubleLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={prev}
              disabled={page === 1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
          <Typography color="white" className="font-normal">
            Page <strong className="text-primary">{page}</strong> of{" "}
            <strong className="text-primary">{total}</strong>
          </Typography>
          <div className="flex flex-row items-center gap-2">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={next}
              disabled={page === total}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={doubleNext}
              disabled={page === total}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronDoubleRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
        </div>
        :
        <></>
      }
    </div>
  )
}
