
import { getAsciiSum } from "./format-data";

export const savePrizeData = async (data) => {
  const url = 'https://api.tapwallet.io/prize/register';

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getPrizeData = async (data) => {
  const url = 'https://api.tapwallet.io/prize/index';

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const savePointData = async (data) => {
  const url = 'https://api.tapwallet.io/point/register';

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getPointData = async (data) => {
  const url = 'https://api.tapwallet.io/point/index';

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getMarketplaceData = async (data) => {
  const url = 'https://api.tapwallet.io/marketplace/index';

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getMarketplaceActivity = async (data) => {
  const url = 'https://api.tapwallet.io/marketplace/activity';

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const saveMarketplaceData = async (data) => {
  const url = 'https://api.tapwallet.io/marketplace/register';

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const saveTokenTransferMintData = async (data) => {
  const url = 'https://api.tapwallet.io/mint/register';

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getMarketplacePsbt = async (data) => {
  const url = 'https://api.tapwallet.io/ordinal/generatePsbtForSale';
  // let response = await getInscriptionData(data.inscriptionId);

  // let output = response.output.split(":");
  // let txId = output[0];
  // let vout = output[1];

  // data.txId = txId;
  // data.vout = vout;

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.text();
}

const getTxHexById = async (txId) => {
  const url =  `https://mempool.space/api/tx/${txId}/hex`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.text();
}

export const getTxDataById = async (txId) => {
  const url =  `https://mempool.space/api/tx/${txId}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}
// export const getInscriptionData = async (inscriptionId) => {
//   const url = `https://api.hiro.so/ordinals/v1/inscriptions/${inscriptionId}`;

//   let response  = await fetch(url, {
//     method: 'GET', 
//     headers: {
//       'Content-Type': 'application/json',
//     }
//   })

//   return await response.json();
// }

export const getInscriptionData = async (inscriptionId) => {
  const url = `https://api.tapwallet.io/getInscriptionData/get?inscriptionId=${inscriptionId}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getSignedPsbt = async (signedPsbt) => {
  const url = 'https://api.tapwallet.io/ordinal/getSignedPsbt';
  const data = {
    signedPsbt: signedPsbt
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  response = await response.text();

  return response;
}

export const getRunes = async (tickers) => {
  const url = 'https://api.tapwallet.io/runes/register';
  const data = {
    tickers: tickers
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getDomainAddress = async (key) => {

  const url = `https://api.tapwallet.io/domain/address?key=${key}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const latestDomain = async (key, offset = 1, limit = 20) => {

  const url = `https://api.tapwallet.io/domain/latest?key=${key}&offset=${offset}&limit=${limit}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const saveDomainActive = async (domain, address) => {
  const url = 'https://api.tapwallet.io/domain/active';
  const data = {
    domain: domain,
    address: address
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getAllDomains = async (key, offset = 1, limit = 20) => {

  const url = `https://api.tapwallet.io/domain/all?key=${key}&offset=${offset}&limit=${limit}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getAllActiveDomains = async (key, offset = 1, limit = 20) => {

  const url = `https://api.tapwallet.io/domain/active/all?key=${key}&offset=${offset}&limit=${limit}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const registerPayment = async (key, address, inscriptionAddress = "") => {
  
  const url = 'https://api.tapwallet.io/payment/register';
  const data = {
    key: key,
    address: address,
    inscription_address: inscriptionAddress,
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const getUnatData = async (address, limit, offset, ticker, blk) => {
  const url = `https://api.tapwallet.io/unat?limit=${limit}&offset=${offset}&address=${address}&ticker=${ticker}&blk=${blk}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getUnatIndex = async (ticker = '', limit = 20, offset = 0, order = 1) => {
  const url = `https://api.tapwallet.io/unat/index?limit=${limit}&offset=${offset}&ticker=${ticker}&order=${order}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getUnatHolders = async (ticker = '', limit = 20, offset = 0, order = 1) => {
  const url = `https://api.tapwallet.io/unat/holders?limit=${limit}&offset=${offset}&ticker=${ticker}&order=${order}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getUnatTickers = async (ticker = '', limit = 20, offset = 0) => {
  const url = `https://api.tapwallet.io/unat/ticker?limit=${limit}&offset=${offset}&ticker=${ticker}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getUnatAddress = async (addressList) => {
  const url = 'https://api.tapwallet.io/unat/address';
  const data = {
    addressList: addressList
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}


export const getInscriptionIds = async (address) => {
  const url = `https://api.brc444.com/fetchOrdinals?address=${address}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const registerDomain = async (_address, domain, address) => {
  
  const url = 'https://api.tapwallet.io/domain/register';
  const data = {
    _address: _address,
    domain: domain,
    address: address
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

}

export const transferDomain = async(domain, address) => {
  const url = 'https://api.tapwallet.io/domain/transfer';
  const data = {
    _address: getAsciiSum(domain + address),
    domain: domain,
    address: address
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}
export const checkDomain = async (domain) => {
  
  const url = 'https://api.tapwallet.io/domain/check';
  const data = {
    domain: domain
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const lockDomain = async (domain) => {
  
  const url = 'https://api.tapwallet.io/domain/lock';
  const data = {
    domain: domain
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const unlockDomain = async (domain) => {
  
  const url = 'https://api.tapwallet.io/domain/unlock';
  const data = {
    domain: domain
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

// export const getUnat = async (inscriptionIds) => {
  
//   const url = 'https://api.tapwallet.io/unat';
//   const data = {
//     inscriptionIds: inscriptionIds
//   };

//   let response  = await fetch(url, {
//     method: 'POST', 
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(data),
//   })

//   return await response.json();
// }

export const getPaymentHistory = async () => {
  const url = 'https://api.tapwallet.io/payment_history';

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const savePaymentHistory = async (value) => {
  const url = 'https://api.tapwallet.io/payment_history';
  const data = {
    value: value
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const calculateFee = (vins, vouts, recommendedFeeRate, includeChangeOutput = 0 ) => {
  if (typeof recommendedFeeRate !== "number" || recommendedFeeRate <= 0)
    throw new Error("Invalid fee rate.");

  const baseTxSize = 10;
  const inSize = 57.5;
  const outSize = 43;
  const txSize = baseTxSize + vins * inSize + vouts * outSize + includeChangeOutput * outSize;
  const fee = Math.round(txSize * recommendedFeeRate);
  return fee;
}

export const updateUnat = async (address) => {
  const url = 'https://api.tapwallet.io/unat/update';
  const data = {
    address: address,
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

// export const getPaymentUtxos = async (paymentAddress, fundingAddress, fundingAmount, paymentPubkey, feeRate) => {
  
//   const url = 'https://api.brc444.com/getPaymentUtxos';
//   const data = {
//     paymentAddress: paymentAddress,
//     fundingAddress: fundingAddress,
//     fundingAmount: fundingAmount,
//     paymentPubkey: paymentPubkey,
//     feeRate: Math.round(feeRate * 1.3)
//   };

//   let response  = await fetch(url, {
//     method: 'POST', 
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(data),
//   })

//   return await response.json();
// }

export const getPaymentTx = async (psbtBase64, signIndexes) => {
  
  const url = 'https://api.tapwallet.io/getPaymentTx';
  const data = {
    psbtBase64: psbtBase64,
    signIndexes: signIndexes
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}


export const getUnisatBrc20 = async(address, cursor, size) => {
  const url = 'https://wallet-api.unisat.io/v5/brc20/list';
  const params = new URLSearchParams({
    address: address,
    cursor: cursor,
    size: size
  });

  const headers = new Headers();
  headers.append('X-Client', 'UniSat Wallet');
  headers.append('X-Version', "1.2.5");
  headers.append('x-address', address);
  headers.append('x-flag', 0 + '');
  headers.append('x-channel', "store");
  headers.append('x-udid', 'UkVxslRzcRkF');

  try {
    const response = await fetch(`${url}?${params}`, {
      method: 'GET', // GET is the default method and can be omitted.
      headers,
      mode: 'cors', cache: 'default'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data;
  } catch (e) {
    console.error('Fetch error:', e.message);
  }
}

export const postUnisatTransaction = async(hex) => {
  const url = 'https://wallet-api.unisat.io/v5/tx/broadcast';

  // const headers = new Headers();
  // headers.append('X-Client', 'UniSat Wallet');
  // headers.append('X-Version', "1.2.5");
  // // headers.append('x-address', address);
  // headers.append('x-flag', 0 + '');
  // headers.append('x-channel', "store");
  // headers.append('x-udid', 'UkVxslRzcRkF');

  try {
    const response = await fetch(url, {
      method: 'POST', // GET is the default method and can be omitted.
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({rawtx: hex})
    });
    if (!response.ok) {
      return "";
    }

    const data = await response.json();
    return data.data;
  } catch (e) {
    console.error('Fetch error:', e.message);
    return "";
  }
}

export const getOrdinals = async(address, cursor, size = 100) => {
  const url = 'https://wallet-api.unisat.io/v5/ordinals/inscriptions';
  const params = new URLSearchParams({
    address: address,
    cursor: cursor,
    size: size
  });

  const headers = new Headers();
  headers.append('X-Client', 'UniSat Wallet');
  headers.append('X-Version', "1.2.5");
  headers.append('x-address', address);
  headers.append('x-flag', 0 + '');
  headers.append('x-channel', "store");
  headers.append('x-udid', 'UkVxslRzcRkF');

  try {
    const response = await fetch(`${url}?${params}`, {
      method: 'GET', // GET is the default method and can be omitted.
      headers,
      mode: 'cors', cache: 'default'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data;
  } catch (e) {
    console.error('Fetch error:', e.message);
  }
}

export const getUnisatRunes = async(address, cursor, size = 100) => {
  const url = 'https://wallet-api.unisat.io/v5/runes/list';
  const params = new URLSearchParams({
    address: address,
    cursor: cursor,
    size: size
  });

  const headers = new Headers();
  headers.append('X-Client', 'UniSat Wallet');
  headers.append('X-Version', "1.3.4");
  headers.append('x-address', address);
  headers.append('x-flag', 0 + '');
  headers.append('x-channel', "store");
  headers.append('x-udid', 'UkVxslRzcRkF');

  try {
    const response = await fetch(`${url}?${params}`, {
      method: 'GET', // GET is the default method and can be omitted.
      headers,
      mode: 'cors', cache: 'default'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data;
  } catch (e) {
    console.error('Fetch error:', e.message);
  }
}

export const getInscriptionContent =  async (inscriptionId) => {
  const url = new URL(`https://ordinals.com/content/${inscriptionId}`);
  let data = ""
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    data = await response.text();
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  finally {
    return data;
  }
}

export const getBlockData =  async (blockNumber) => {
  const url = new URL(`https://ordinals.com/r/blockinfo/${blockNumber}`);
  let data = ""
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    data = await response.json();
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  finally {
    return data;
  }
}

export const getBtcPrice =  async () => {
  let url = new URL(`https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd`);
  let data = ""
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    data = await response.json();
    return data.bitcoin.usd;
  } catch (error) {
    url = new URL(`https://api.coinbase.com/v2/prices/BTC-USD/spot`);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      data = await response.json();
      return parseInt(data.data.amount);
    }
    catch(error) {

    }
  }
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

