import { apiUrl4 } from "../configs/constant";

export const saveAdminStatus = async (terms, status) => {
  const url = `${apiUrl4}/admin/status/update`;

  const data = {
    terms,
    status
  }

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getStatus = async (terms) => {
  const url = `${apiUrl4}/status/index`;

  const data = {
    terms
  }
  
  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}