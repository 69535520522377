import React, { useRef, useState } from 'react'
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { combineAddress, formatAddressMicroText } from '../../util/format-data'
import { getFirestore, collection, query, orderBy, onSnapshot, addDoc, serverTimestamp, doc, setDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import EmojiPicker, { Emoji, EmojiStyle, Theme } from 'emoji-picker-react';
import { FaceSmileIcon } from '@heroicons/react/24/outline';

export default function MessageInput(props) {
  const {activeContact, address} = props
  const inputRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const sendMessage = async () => {
    let message = inputRef.current.innerText;
    if (message.trim() != "") {
      await addDoc(collection(firestore, 'chat-' + combineAddress(activeContact, address)), {
        message: message,
        from: address,
        to: activeContact,
        isDeleted: false,
        isRead: false,
        timestamp: serverTimestamp(),
      });
      inputRef.current.innerText = "";

      const docRef = doc(firestore, "chat-" + formatAddressMicroText(activeContact), address);
      const data = {
        address: address,
        typing: false,
        count: 0,
        isRead: false,
        status: false,
        message: message,
        timestamp: serverTimestamp(),
      }
  
      await setDoc(docRef, data, { merge: true});
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); 
      sendMessage();
    }
  };


  const handleEmojiSelect = (emoji) => {
    inputRef.current.innerText = inputRef.current.innerText + emoji.emoji;
    setIsOpen(false);
  }
  return (
    <div className="flex flex-row w-full border-t-[2px] border-t-[#24242a] px-6 py-3 gap-2 items-end relative">
        <div 
          ref={inputRef}
          className="rounded-md border-[2px] border-[#24242a] min-h-[40px] w-full text-white pl-8 py-2 pr-2 break-all whitespace-normal" 
          contentEditable = {true}
          suppressContentEditableWarning={true}
          onKeyDown={handleKeyDown}
        >
        </div>

        <div className="fixed right-0 z-[9999]">
          <EmojiPicker
            open={isOpen}
            theme={Theme.DARK}
            onEmojiClick={handleEmojiSelect}
            emojiVersion="4.0"
            emojiStyle={EmojiStyle.TWITTER}
          />
        </div>

        <div className="absolute left-8 top-6 text-white" onClick={() => {setIsOpen(true)}}>
          <FaceSmileIcon className="h-5 w-5 cursor-pointer" />
        </div>

        <div className="text-primary p-2 rounded-lg border-[2px] border-primary cursor-pointer hover:bg-primary hover:text-white" onClick={() => sendMessage()}>
            <PaperAirplaneIcon strokeWidth={2} className="h-6 w-6" />
        </div>
    </div>
  )
}
