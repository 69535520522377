import React from 'react'
import {
  useLocation
} from "react-router-dom";


export default function Lrc20Card(props) {
  const { data, handle } = props;
  const location = useLocation();

  return (
    <div 
      className="flex flex-col border-borderColor border-[1px] border-solid bg-gray-900 p-4 rounded-md cursor-pointer">
      <div className="text-primary font-bold text-[20px]">{data.tick.toUpperCase()}</div>
      <div>ID: {data.contract}</div>
      <div className="mt-3">{data.amt} <span className="font-bold text-blue-800">{data.symbol}</span></div>
      {
        location.pathname.includes('/profile') ?
        <div className="flex flex-row w-full">
          <div onClick={() => handle(data)} className="rounded-md hover:text-primary hover:border-primary px-8 py-1 w-full font-bold mt-4 text-center text-white cursor-pointer border-[1px] border-solid border-white">Trade</div>
        </div>
        : <></>
      }
    </div>
  )
}
