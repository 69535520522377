export const encodedAddressPrefix = 'main';
export const padding = 546;
export const tip = 0;
export const royalty = 100000;
export const royalty_name = 20000;
export const royalty_pa = 10000;
// export const royalty = 444;
export const tippingAddress = 'bc1p3ns3qgpmyv4j4q00kauvf0xnpg42kf9rc5259n5g3247vhr7yqeqcca3fv';
export const ownerAddress = 'bc1px0j5z4srk2u67rl8qkwcyk7agujzds6vpwh26f4hqpx9w9e7x7qqd74ar9';
export const mempoolNetwork = '';
export const include_mempool = true;
export const marketTip = 2000;
export const marketTipAddress = 'bc1p3ns3qgpmyv4j4q00kauvf0xnpg42kf9rc5259n5g3247vhr7yqeqcca3fv';
export const NUMBER_OF_DUMMY_UTXOS_TO_CREATE = 2;
export const DUMMY_UTXO_VALUE = 600;
export const MIN_UTXO_VALUE = 1000;
export const contentLength = 440;
export const serviceFeeRate = 0.05;
export const marketplaceFeeLimit = 5000;
export const marketplaceFeeRate = 0.02;
export const stampMarketplaceFeeRate = 0.01;
export const lambdaAddress = 'bc1p95qh2fp0x0wrers29fyq84aykkn2jvr05hdf7rzdhmzgz4rw969sr5ugnf';
export const lambdaApproveFee = 1500;
export const stampFeeAddress = 'bc1pl0xdhr5delrk80d6s5h5qa9awsf7qzc45jl8na6ns8vp3e0vv7wqxattxy';

// export const stampFeeAddress = 'bc1qlqcws9hse60qtua7sx8crety7suua96f2us0q2';

export const apiUrl1 = "https://api.tapwallet.io"
export const apiUrl2 = "https://api2.tapwallet.io"
export const apiUrl4 = "https://api4.tapwallet.io"