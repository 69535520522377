import React from 'react'

export default function MessageText(props) {
  const {name, message, flag} = props;

  const formatMessage = (message) => {
    return { __html: message.replace(/\n/g, '<br/>') };
  };

  return (
    <div className={`flex ${flag ? 'flex-row-reverse' : 'flex-row'} gap-1`}>
      <div className={`${flag ? 'hidden' : ''} w-[40px] h-[40px] rounded-full text-center bg-primary text-white font-bold text-[24px]`}>{name}</div>
      <div 
        className={`md:max-w-[400px] max-w-[260px] break-words whitespace-normal text-white py-2 px-6 ${flag ? 'bg-blue-gray-900' : 'bg-gray-900'} rounded-md`}
        dangerouslySetInnerHTML={formatMessage(message)}
      ></div>
    </div>
  )
}
