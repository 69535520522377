import React, {useState, useEffect} from 'react'
import TokenCard from '../TokenCard'
import { useSelector, useDispatch } from 'react-redux';
import { getStampBalance } from '../../util/stamp-api';
import { v4 as uuidv4 } from 'uuid';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  Spinner,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Slider
} from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import StampCard from '../TokenSaleCard/StampCard';
import toast, { Toaster } from 'react-hot-toast';
import { getBtcPrice } from '../../util/api';
import { useWallet, useWallets } from '@wallet-standard/react';
import { lambdaAddress, marketplaceFeeLimit, encodedAddressPrefix, padding, tip, tippingAddress, royalty, lambdaApproveFee, marketplaceFeeRate, stampMarketplaceFeeRate } from '../../configs/constant';
import { BitcoinNetworkType, signMessage, signTransaction, sendBtcTransaction } from 'sats-connect';
import {
  useLocation
} from "react-router-dom";
import { getMarketplaceData, saveMarketplaceData, savePointData } from '../../util/api';
import { formatFloat } from '../../util/format-data';
import { amountFormat } from '../../util/format-data';
import FeeRateCardNew from '../FeeRateCardNew';
import { getFeeRate } from '../../util/inscribe-util';
import { getBtcUtxos } from '../../util/ordinal';
import { getStampAttach, getStampDetach, getStampSalesPsbt } from '../../util/stamp-api';
import { getTapTokenTickerBalance } from '../../util/tap-api';
import { getSignedPsbt } from '../../util/api';
import { getStatus } from '../../util/admin-api';

export default function Stamp(props) {
  const { keyAddress } = props;

  const { wallets } = useWallets();
  const location = useLocation();
  const wallet = useSelector(state => state.wallet);

  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const { Address, Script, Signer, Tap, Tx } = window.tapscript;
  const feeRateTabs = ["Slow", "Normal", "Fast", "Custom"];

  const [feeRateMode, setFeeRateMode] = useState("Normal");
  const [feerate, setFeerate] = useState(0);
  const [feeRates, setFeeRates] = useState({});
  const [customFee, setCustomFee] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [price, setPrice] = useState(0);
  const [currentFee, setCurrentFee] = useState(0);
  const [stampData, setStampData] =  useState([]);
  const [loading, setLoading] = useState(false);
  const [marketplaceData, setMarketplaceData] = useState([]);
  const [marketplaceDetailData, setMarketplaceDetailData] = useState({});
  const [showDetail, setShowDetail] = useState(false);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(1);
  const [detailData, setDetailData] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [show, setShow] = useState(false);
  const [mode, setMode] = useState(0);
  const [mintAmount, setMintAmount] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchAllstamp();
      }
      catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [keyAddress, page])

  useEffect(() => {
    const fetchBtcPrice = async () => {
      let tempPrice = await getBtcPrice();
      setPrice(tempPrice);
    }
    fetchBtcPrice();
  }, [])

  useEffect(() => {
    let intervalId;

    const updateFees = async () => {
      let response = await getFeeRate();
      setFeeRates(response);
      setFeerate(response[feeRateMode]);
      if (customFee == 0) 
      {
        setCustomFee(response["Fast"] + 1);
      }

      if (feeRateMode == "Custom") {
        setCurrentFee(customFee);
        // setTotalFee(calculateInscribeFee(customFee)["totalFee"]);
      }
      else {
        setCurrentFee(response[feeRateMode]);
        // setTotalFee(calculateInscribeFee(response[feeRateMode])["totalFee"]);
      }
    }
    updateFees();
    intervalId = setInterval(updateFees, 10 * 1000);
    return () => {
      clearInterval(intervalId);
    }
  }, [customFee, feeRateMode, mintAmount])

  useEffect(() => {
    if (sliderValue != 0) {
      let value = (sliderValue / 100) * (500 - feeRates["Normal"]) + feeRates["Normal"];
      setCustomFee(Math.floor(value));
    }
  }, [sliderValue])

  const fetchAllstamp = async() => {
    setLoading(true);
    try{
      let data = await getStampBalance(keyAddress, limit, page);
      if (data.data == undefined) {
        setStampData([]);
        setTotal(0);
      }
      else {
        setStampData(data.data);
        if (data.pagination != undefined) {
            if (data.pagination.totalPages == null) setTotal(0)
            else setTotal(data.pagination.totalPages);
        }
        else {
            if (data.totalPages == null) setTotal(0)
            else setTotal(data.totalPages); 
        }
        
      }
    }
    catch(e) {
      console.log(e);
      setLoading(false);
    }
    setLoading(false);
  }

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const handleDetail = async (data) => {

    if (!location.pathname.includes('/profile')) return;

    setLoading(true);

    const {listed, listPrice} = await updateMarketplaceData(data);
    data.listed = listed;
    data.totalPrice = listPrice;

    setDetailData(data);
    setShowDetail(true);
    setLoading(false);
  }

  const updateMarketplaceData = async (data = detailData) => {
    let param = {
      ownerAddress: wallet.nostrOrdinalsAddress,
      ticker: data.stamp,
      deleteFlag: 0
    }
    let resData = await getMarketplaceData(param);

    let listed = 0;
    let listPrice = 0;
    let tempMarketplaceData = [];
    if (resData.total > 0) {
      for(let tempData of resData.data) {
        listed += tempData.amount;
        listPrice += tempData.totalPrice;
        tempMarketplaceData.push(tempData);
      }
    }

    for(let tempData of data.utxos) {
      let flag = 0;
      for(let tempData1 of resData.data) {
        if (tempData.utxo == tempData1.inscriptionId) {
          flag = 1;
          break;
        }
      }
      if (flag == 0) {
        tempMarketplaceData.push({
          inscriptionId: tempData.utxo,
          amount: tempData.quantity
        })
      }
    }

    setMarketplaceData(tempMarketplaceData);
    return {listed, listPrice}
  }

  const handleMintAmount = (e) => {
    setMintAmount(e.target.value);
  }

  const handleMintPrice = (e) => {
    setUnitPrice(e.target.value);
  }

  const handleAttach = () => {
    setShow(true);
    setMode(0);
  }

  const handleDetach = (data) => {
    setShow(true);
    setMarketplaceDetailData(data);
    setMode(4);
  }

  const handleList = (data) => {
    setShow(true);
    setMarketplaceDetailData(data);
    setMode(1);
  }

  const handleDelist = (data) => {
    setShow(true);
    setMarketplaceDetailData(data);
    setMode(3);
  }

  const handleUpdate = (data) => {
    setShow(true);
    setUnitPrice(data.price);
    setMarketplaceDetailData(data);
    setMode(2);
  }

  const handleMint = async () => {
    let resStatus = await getStatus("stamp_list");
    if (resStatus.data.status == 0) {
      toast.error("Please wait. Stamp api is under maintanance!");
      setShow(false);
      return;
    }

    if (!wallet.nostrOrdinalsAddress.includes("bc1q")) {
      toast.error("Only support segwit address!");
      setShow(false);
      return;
    }
    setShowSpinner(true);
    let paymentUtxos = {};
    if (mode == 0) {
      if (mintAmount <= 0) {
        toast.error("Please insert valid amount!");
        setShow(false);
        setShowSpinner(false);
        return;
      }
      if (mintAmount > detailData.unbound_quantity) {
        toast.error("Balance is not enough!");
        setShow(false);
        setShowSpinner(false);
        return;
      }

      let utxos = await getBtcUtxos(wallet.nostrPaymentAddress);
      let response = await getStampAttach(utxos, detailData.cpid, mintAmount, parseFloat(currentFee), wallet.nostrPaymentAddress)

      if (response.status == "error") {
        toast.error(response.message);
        setShow(false);
        setShowSpinner(false);
        return;
      }
      paymentUtxos = response;
    }

    if (mode == 4) {
      let utxos = await getBtcUtxos(wallet.nostrPaymentAddress);
      let response = await getStampDetach(utxos, marketplaceDetailData.inscriptionId, parseFloat(currentFee), wallet.nostrPaymentAddress)
      if (response.status == "error") {
        toast.error(response.message);
        setShow(false);
        setShowSpinner(false);
        return;
      }
      paymentUtxos = response;
    }

    let totalPrice = marketplaceDetailData.amount * unitPrice;
    let actualPrice = totalPrice;
    let signedPsbtNew;

    if (mode == 1 || mode == 2) {
      
      if (totalPrice < 2 * marketplaceFeeLimit) {
        toast.error("Total price should be grater than 0.00001 btc!");
        setShow(false);
        setShowSpinner(false);
        return;
      }

      if (totalPrice * stampMarketplaceFeeRate < marketplaceFeeLimit) {
        actualPrice = totalPrice - marketplaceFeeLimit;
      }
      else {
        actualPrice = totalPrice - totalPrice * stampMarketplaceFeeRate;
      }
    }

    if (mode == 0 || mode == 4) {
      try{
        if (wallet.domain == "tapwallet") {
          const signedPsbt = await window.tapwallet.signPsbt(paymentUtxos.psbt);
          const txid = await window.tapwallet.pushPsbt(signedPsbt);
        }
        if (wallet.domain == "unisat") {
          const signedPsbt = await window.unisat.signPsbt(paymentUtxos.psbt);
          const txid = await window.unisat.pushPsbt(signedPsbt);
        }
        if (wallet.domain == "okxwallet") {
          const signedPsbt = await window.okxwallet.bitcoin.signPsbt(paymentUtxos.psbt);
          const txid = await window.okxwallet.bitcoin.pushPsbt(signedPsbt);
        }
        if (wallet.domain == "xverseWallet") {
          let res = paymentUtxos;
          if (res.status == "success") {
            let signIndexes = [];
            for(let i=0;i<res.count; i++){
              signIndexes.push(i);
            }
  
            await signTransaction({
              payload: {
                  network: {
                      type: BitcoinNetworkType.Mainnet,
                  },
                  psbtBase64: res.psbt,
                  broadcast: true,
                  message: "tip the author! Don't worry this will not be broadcasted.",
                  inputsToSign: [
                      {
                          address: wallet.nostrPaymentAddress,
                          signingIndexes: signIndexes,
                      },
                  ],
              },
              onFinish: async (response) => {
                
              },
              onCancel: () => {
                  alert('Request canceled');
                  setShow(false);
                  setShowSpinner(false);
                  return;
              },
            });
    
          }
          else {
            alert("Insufficient balance.");
            setShow(false);
            setShowSpinner(false);
            return;
          }
        }
        if (wallet.domain == "magiceden") {
          let res = paymentUtxos;
          if (res.status == "success") {
            let signIndexes = [];
            for(let i=0;i<res.count; i++){
              signIndexes.push(i);
            }
  
            let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);
  
            await signTransaction({
              getProvider: async () =>
                magicedenWallets[0].features['sats-connect:'].provider,
              payload: {
                  network: {
                      type: BitcoinNetworkType.Mainnet,
                  },
                  psbtBase64: res.psbt,
                  broadcast: true,
                  message: "tip the author! Don't worry this will not be broadcasted.",
                  inputsToSign: [
                      {
                          address: wallet.nostrPaymentAddress,
                          signingIndexes: signIndexes,
                      },
                  ],
              },
              onFinish: async (response) => {
              },
              onCancel: () => {
                  alert('Request canceled');
                  setShow(false);
                  setShowSpinner(false);
                  return;
              },
            });
    
          }
          else {
            alert("Insufficient balance.");
            setShow(false);
            setShowSpinner(false);
            return;
          }
        }
      }
      catch(e)
      {
        console.log(e);
        alert("Payment rejected by user. Try again.");
        setShow(false);
        setShowSpinner(false);
        return;
      }
    }
    else {
      if (mode == 3) actualPrice = 10000 * Math.pow(10, 8);

      let response = await getStampSalesPsbt(marketplaceDetailData.inscriptionId, parseInt(actualPrice), wallet.nostrPaymentAddress);

      try{
        if (wallet.domain == "tapwallet") {
          const signedPsbt = await window.tapwallet.signPsbt(response);
          signedPsbtNew = await getSignedPsbt(signedPsbt);
        }
        if (wallet.domain == "unisat") {
          const signedPsbt = await window.unisat.signPsbt(response);
          signedPsbtNew = await getSignedPsbt(signedPsbt);
        }
        if (wallet.domain == "okxwallet") {
          const signedPsbt = await window.okxwallet.bitcoin.signPsbt(response);
          signedPsbtNew = await getSignedPsbt(signedPsbt);
        }
        if (wallet.domain == "xverseWallet") {
          let signIndexes = [];
          for(let i=0;i<1; i++){
            signIndexes.push(i);
          }
  
          await signTransaction({
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: response,
                broadcast: false,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                        sigHash: 131
                    },
                ],
            },
            onFinish: async (res) => {
              signedPsbtNew = res.psbtBase64;
            },
            onCancel: () => {
                alert('Request canceled');
                setShow(false);
                setShowSpinner(false);
                return;
            },
          });
    
        }
        if (wallet.domain == "magiceden") {
          let signIndexes = [];
          for(let i=0;i<1; i++){
            signIndexes.push(i);
          }
  
          let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

          await signTransaction({
            getProvider: async () =>
              magicedenWallets[0].features['sats-connect:'].provider,
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: response,
                broadcast: false,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                        sigHash: 131
                    },
                ],
            },
            onFinish: async (res) => {
              signedPsbtNew = res.psbtBase64;
            },
            onCancel: () => {
                alert('Request canceled');
                setShow(false);
                setShowSpinner(false);
                return;
            },
          });
        }
      }
      catch(e)
      {
        console.log(e);
        alert("Payment rejected by user. Try again.");
        setShow(false);
        setShowSpinner(false);
        return;
      }
    }

    if (mode == 0 || mode == 4) {
      toast.success("Transaction broadcasted successfully! Please wait for confirmation.")
    }

    if (mode == 1) { // List
      let params = {
        ownerAddress : wallet.nostrPaymentAddress,
        inscriptionId : marketplaceDetailData.inscriptionId,
        ticker: detailData.stamp,
        type: 7, // stamp
        amount: marketplaceDetailData.amount,
        price: unitPrice,
        psbt: signedPsbtNew,
        reason: "sale",
        actualPrice: actualPrice,
        totalPrice: totalPrice,
        contentId: detailData.stamp_url,
        number: detailData.cpid
      }
      await saveMarketplaceData(params);

      params = {
        address : wallet.nostrPaymentAddress,
        point : 1,
        reason : "List STAMP",
        deleteFlag : 0
      }
      await savePointData(params);
      toast.success("Your item(s) have been listed!");
    }
    else if (mode == 2) { // Edit
      let params = {
        id: marketplaceDetailData.id,
        price: unitPrice,
        psbt: signedPsbtNew,
        reason: "edit",
        totalPrice: totalPrice,
        actualPrice: actualPrice
      }

      await saveMarketplaceData(params);
      toast.success("Your item(s) have been updated!");
    }
    else if (mode == 3) { // Delist
      let params = {
        id: marketplaceDetailData.id,
        reason: 'delist',
        psbt: signedPsbtNew
      }

      await saveMarketplaceData(params);

      params = {
        address : wallet.nostrOrdinalsAddress,
        point : -1,
        reason : "Delist Tap/Dmt",
        deleteFlag : 0
      }
      await savePointData(params);
      toast.success("Your item(s) have been delisted!");
    }

    setShow(false);
    setShowSpinner(false);
    setLoading(true);

    const {listed, listPrice} = await updateMarketplaceData(detailData);
    let tempData = detailData;
    tempData.listed = listed;
    tempData.totalPrice = listPrice;

    setDetailData(tempData);
    setLoading(false);

    return;
  }

  const handleCustomFee = (value) => {
    const sanitizedValue = value.replace(/[^0-9.]/g, '');
    setCustomFee(sanitizedValue);
  }

  return (
    <div className="flex flex-col w-full">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      {
        showDetail ? 
        <div className="flex flex-col">
          <div className="mt-3 font-bold text-primary text-[24px] flex flex-row gap-2 items-center">
            <ArrowLeftIcon strokeWidth={3} className="h-5 w-5 cursor-pointer" onClick={() => setShowDetail(false)}/>
            <div className="sm:text-[24px] text-[20px]" >STAMP {detailData.stamp}</div>
          </div>
          <div className="flex flex-col gap-3 md:p-6 p-3 justify-center items-center rounded-md border-[1px] border-borderColor border-solid max-w-[500px] m-auto mt-5">
            <div className="relative w-full sm:h-[150px] h-[120px]">
              <img
                  src={detailData.stamp_url}
                  alt="Stamp"
                  className="w-full h-full object-contain rounded-md image-rendering-pixelated"
              />
            </div>
            <div className="flex flex-row gap-2 items-center md:min-w-[300px] min-w-[250px] justify-between">
              <div>CPID:</div>
              <div className="font-bold md:text-[20px] text-[16px] text-primary text-right">{detailData.cpid}</div>
            </div>
            <div className="flex flex-row gap-2 items-center md:min-w-[300px] min-w-[250px] justify-between">
              <div>Total Amount:</div>
              <div className="font-bold md:text-[20px] text-[16px] text-primary text-right">{detailData.balance}</div>
            </div>
            <div className="flex flex-row gap-2 items-center md:min-w-[300px] min-w-[250px] justify-between">
              <div>Attached Amount:</div>
              <div className="font-bold md:text-[20px] text-[16px] text-primary text-right">{detailData.balance - detailData.unbound_quantity}</div>
            </div>
            <div className="flex flex-row gap-2 items-center md:min-w-[300px] min-w-[250px] justify-between">
              <div>Listed Amount:</div>
              <div className="font-bold md:text-[20px] text-[16px] text-primary text-right">{detailData.listed}</div>
            </div>
            <div className="flex flex-row gap-2 md:min-w-[300px] min-w-[250px] justify-between">
              <div className="md:mt-1 mt-0">Total Price:</div>
              <div className="flex flex-col gap-1">
                <div className="font-bold md:text-[20px] text-[16px] text-primary text-right">( {formatFloat(detailData.totalPrice / Math.pow(10, 8), 6)} BTC )</div>
                <div className="font-bold md:text-[20px] text-[16px] text-primary text-right">( {detailData.totalPrice} SATS )</div>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-2">
              <div className="bg-primary px-10 py-2 text-white rounded-md cursor-pointer hover:bg-primary-hover" onClick = {() => handleAttach()}>ATTACH</div>
            </div>
          </div>
          <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 w-full mt-8">
          {
              marketplaceData.map((data) => 
                  <div className="w-full max-w-[320px] m-auto rounded-md border-borderColor border-[1px] border-solid bg-gray-900 text-[14px]">
                    <div className="relative w-full sm:h-[120px] h-[100px] mt-5 rounded-md">
                      <img
                          src={detailData.stamp_url}
                          alt="Stamp"
                          className="w-full h-full object-contain rounded-md image-rendering-pixelated"
                      />
                    </div>
                    <div className="flex flex-row justify-center text-[24px] font-bold mt-5">
                      {amountFormat(data.amount)}
                    </div>
                    <div className="flex flex-row justify-center items-center mt-3 gap-2">
                      <span className="font-bold text-primary text-[24px]">{data.price ? formatFloat(data.price / Math.pow(10, 8), 6) : 0}</span>
                      <span className="text-gray-400">sats/1</span>
                    </div>
                    <div className="flex flex-row justify-center">
                      ${data.price ? formatFloat(data.price / Math.pow(10, 8) * price, 6) : 0}
                    </div>
                    <div className="bg-[#121212] mt-2 pb-3 px-4 rounded-b-md">
                    <div className="flex flex-row justify-between mt-2 pt-1">
                      <div className="flex flex-row gap-1 items-center">
                        <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="9" fill="#e91d9b"></circle><path d="M13.14 7.965c.185-1.143-.624-1.672-1.078-1.964-.452-.292-1.143-.502-1.143-.502l.442-1.78-1.02-.256-.442 1.78-.815-.206.442-1.78L8.494 3l-.442 1.78-2.157-.542-.262 1.056s1.065.266 1.182.297c.12.029.104.09.094.13-.01.04-1.34 5.405-1.36 5.476-.016.072-.028.113-.127.09l-1.131-.284L4 12.176l2.088.523-.423 1.702 1.113.279.423-1.703.693.174-.423 1.703 1.06.266.424-1.702.798.2c.53.133 2.544.158 2.982-1.611.439-1.77-1.012-2.519-1.012-2.519s1.23-.38 1.417-1.523Zm-2.417 3.282c-.21.847-1.155.912-1.37.857l-1.895-.474.56-2.262 1.965.492c.377.096.951.54.74 1.387Zm.35-3.445c-.224.902-1.124.861-1.408.791L8.28 8.246l.534-2.153 1.264.316c.216.054 1.22.492.995 1.393Z" fill="#fff"></path></svg>
                        <span className="text-primary">{data.price ? formatFloat(data.price * data.amount / Math.pow(10, 8), 6) : 0}</span>
                      </div>
                      <div className="text-gray-400">${data.price ? formatFloat(data.price * data.amount * price  / Math.pow(10, 8), 4) : 0}</div>
                    </div>
                    {
                      !data.price ? 
                      <div className="w-full flex flex-row items-center justify-center mt-4 mb-2 p-1 gap-2">
                        <div className="bg-primary px-10 py-2 text-white rounded-md cursor-pointer hover:bg-primary-hover" onClick = {() => handleList(data)}>LIST</div>
                        <div className="bg-primary px-5 py-2 text-white rounded-md cursor-pointer hover:bg-primary-hover" onClick = {() => handleDetach(data)}>DETACH</div>
                      </div>
                      :
                      <div className="w-full flex flex-row items-center justify-center mt-4 mb-2 p-1 gap-2">
                        <div className="bg-primary px-8 py-2 text-white rounded-md cursor-pointer hover:bg-primary-hover" onClick = {() => handleUpdate(data)}>UPDATE</div>
                        <div className="bg-primary px-8 py-2 text-white rounded-md cursor-pointer hover:bg-primary-hover" onClick = {() => handleDelist(data)}>DELIST</div>
                      </div>
                    }
                    
                  </div>
                </div>
              )
          }
          </div>
        </div>
        :
        <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-3 w-full mt-5">
        {
          stampData.length > 0 ? 
            stampData.map((data) => {
              return <StampCard key={uuidv4()} data = {data} handle = {handleDetail}/>
            }) : <>No STAMP.</>
        }
        </div>
      }
      {
        total > 1 ? 
        <div className="flex flex-row items-center gap-8 justify-end mt-5">
          <IconButton
            size="sm"
            variant="outlined"
            onClick={prev}
            disabled={page === 1}
            className="bg-primary hover:bg-primary-hover"
          >
            <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
          <Typography color="white" className="font-normal">
            Page <strong className="text-primary">{page}</strong> of{" "}
            <strong className="text-primary">{total}</strong>
          </Typography>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={next}
            disabled={page === total}
            className="bg-primary hover:bg-primary-hover"
          >
            <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
        </div>
        :
        <></>
      }
      <Dialog
        open={show}
        size={"sm"}
        className="bg-black border-solid border-[1px] border-gray-600"
      >
        <DialogBody>
          <div className="flex flex-col gap-4 w-full items-center sm:p-3 p-1">
            <div className="font-bold text-[32px] text-primary mt-4">
              { mode == 0 ? 'ATTACH' : mode == 1 ? 'LIST' : mode == 2 ?  'UPDATE' : mode == 3 ? 'DELIST' : 'DEATTACH'}
            </div>
            <div className="text-[24px] font-bold text-white">
              STAMP {detailData.stamp}
            </div>
            {
              mode == 1 || mode == 2 ? // LIST, UPDATE case
               <>
                <div className="w-full font-bold text-white sm:text-[28px] text-[24px] text-center">
                  ${formatFloat(unitPrice * marketplaceDetailData.amount * price / Math.pow(10, 8), 2)}
                </div>
                <div className="w-full max-w-[320px] grid grid-cols-2 m-auto">
                  <div className="w-full font-bold text-white sm:text-[16px] text-[14px] text-left">
                    <div>Price:</div>
                    <div>Price per unit:</div>
                  </div>
                  <div className="w-full font-bold text-white sm:text-[16px] text-[14px] text-right mb-2">
                    <div>{formatFloat(unitPrice * marketplaceDetailData.amount / Math.pow(10, 8), 6)} BTC</div>
                    <div>{formatFloat(unitPrice / Math.pow(10, 8), 6)} BTC</div>
                  </div>
                </div>
              </>
              : <></>
            }
            {
              mode == 0 ? // ATTACH
              <div className="flex flex-row items-center gap-1 justify-center">
                <div className="sm:w-[270px] w-[200px]">
                  <Input color="white" label = "Insert Amount" value = {mintAmount} onChange = {(e) => {handleMintAmount(e)}}/>
                </div>
              </div>
              : <></>
            }
            {
              mode == 0 || mode == 3 || mode == 4 ? // LIST, UPDATE case
                <></>
                :
                <div className="flex flex-row items-center gap-1 justify-center">
                  <div className="sm:w-[270px] w-[200px]">
                    <Input color="white" label = "Insert Price" value = {unitPrice} onChange = {(e) => {handleMintPrice(e)}}/>
                  </div>
                  <span className="font-bold ml-1">sats</span>
                </div>
            }
            {
              mode == 1 || mode ==2 || mode == 3 ? // LIST, UPDATE, DELIST case
              <></>
              :
              <>
                <div className="w-full text-white font-bold">
                  Network Fee:
                </div>
                <div className="grid grid-cols-3 gap-3 w-full">
                  <FeeRateCardNew header={feeRateTabs[1]} rate={feeRates[feeRateTabs[1]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[1])}}/>
                  <FeeRateCardNew header={feeRateTabs[2]} rate={feeRates[feeRateTabs[2]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[2])}}/>
                  <FeeRateCardNew header={feeRateTabs[3]} rate={customFee} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[3])}}/>
                </div>
                <div className="sm:text-[24px] text-[16px] font-bold text-white">
                  Total Fee: {totalFee / Math.pow(10, 8)} BTC
                </div>
                {
                  feeRateMode == "Custom" ? 
                  <div className="flex flex-row gap-3 items-center">
                    <Slider className="sm:flex hidden" color="blue" value = {sliderValue} onChange = {(e) => setSliderValue(e.target.value)}/>
                    <div className="sm:max-w-[200px] w-full">
                      <Input type="text" color="white" label = "Custom Fee" className="sm:max-w-[200px] w-full" value={customFee} onChange = {(e) => {handleCustomFee(e.target.value)}}/>
                    </div>
                  </div>
                  : 
                  <></>
                }
              </>
            }
            {
              showSpinner ? 
                <Spinner className="h-12 w-12" color="red"/>:
                <div className="flex flex-row gap-3">
                  <button className="bg-primary hover:bg-primary-hover text-white rounded-md sm:px-10 px-5 font-bold py-2" onClick={() => handleMint()}>{ mode == 0 ? 'ATTACH' : mode == 1 ? 'LIST' : mode == 2 ?  'UPDATE' : mode == 3 ? 'DELIST' : 'DEATTACH'}</button>
                  <button className="bg-[#212121] text-white rounded-md sm:px-10 px-5 font-bold py-2" onClick={() => setShow(false)}>Return</button>
                </div>
            }
          </div>
        </DialogBody>
      </Dialog>
    </div>
  )
}
