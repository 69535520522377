import React, {useState, useEffect} from 'react'
import TokenCard from '../TokenCard'
import { getUnisatBrc20 } from '../../util/api'
import { useSelector, useDispatch } from 'react-redux';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { v4 as uuidv4 } from 'uuid';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  Spinner,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Slider
} from "@material-tailwind/react";
import {
  useLocation
} from "react-router-dom";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { getLRC20TokenList, getLRC20TokenBalance } from '../../util/lamda-api';
import Lrc20Card from '../TokenSaleCard/Lrc20Card';
import { formatFloat } from '../../util/format-data';
import { getMarketplaceData, saveMarketplaceData } from '../../util/api';
import { getLRC20TokenDetail } from '../../util/lamda-api';
import { lambdaAddress, marketplaceFeeLimit, encodedAddressPrefix, padding, tip, tippingAddress, royalty, lambdaApproveFee } from '../../configs/constant';
import { BitcoinNetworkType, signMessage, signTransaction, sendBtcTransaction } from 'sats-connect';
import { getFeeRate, bytesToHex, buf2hex, textToHex, hexToBytes, getMempoolUtxos, loopTilAddressReceivesMoney, waitSomeSeconds, addressReceivedMoneyInThisTx, pushBTCpmt, calculateFeeReal, getData, isValidTaprootAddress} from '../../util/inscribe-util';
import FeeRateCardNew from '../FeeRateCardNew';
import { useWallet, useWallets } from '@wallet-standard/react';
import { getBtcPrice } from '../../util/api';
import { getPaymentUtxos } from '../../util/new-api';
import { saveTokenTransferMintData } from '../../util/api';
import toast, { Toaster } from 'react-hot-toast';
import { savePointData } from '../../util/api';
import { amountFormat } from '../../util/format-data';

export default function Lrc20(props) {
  const { keyAddress } = props;

  const { wallets } = useWallets();

  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const location = useLocation();
  const wallet = useSelector(state => state.wallet);

  // const [lrc20Data, setLrc20Data] =  useState([
  //   {
  //     "tick": "Trump coin",
  //     "symbol": "TRUMP",
  //     "contract": "dep:dmt:TRUMP",
  //     "amt": 100,
  //     "decimals": 4,
  //   }
  // ]);

  const [lrc20Data, setLrc20Data] =  useState([]);
  const [marketplaceData, setMarketplaceData] = useState([]);

  const { Address, Script, Signer, Tap, Tx } = window.tapscript;
  const feeRateTabs = ["Slow", "Normal", "Fast", "Custom"];

  const [feeRateMode, setFeeRateMode] = useState("Normal");
  const [feerate, setFeerate] = useState(0);
  const [feeRates, setFeeRates] = useState({});
  const [customFee, setCustomFee] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [detailData, setDetailData] = useState({});
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(1);
  const [showDetail, setShowDetail] = useState(false);
  const [show, setShow] = useState(false);
  const [mintAmount, setMintAmount] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [price, setPrice] = useState(0);
  const [currentFee, setCurrentFee] = useState(0);
  const [txid, setTxid] = useState("");
  const [nativeAmount, setNativeAmount] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [mode, setMode] = useState(0);
  const [currentId, setCurrentId] = useState(0);
  const [currentAmount, setCurrentAmount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchAllLrc20();
      }
      catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [keyAddress, page])

  useEffect(() => {
    const fetchBtcPrice = async () => {
      let tempPrice = await getBtcPrice();
      setPrice(tempPrice);
    }
    fetchBtcPrice();
  }, [])

  useEffect(() => {
    let intervalId;

    const updateFees = async () => {
      let response = await getFeeRate();
      setFeeRates(response);
      setFeerate(response[feeRateMode]);
      if (customFee == 0) 
      {
        setCustomFee(response["Fast"] + 1);
      }
      if (detailData.contract != undefined) {
        if (feeRateMode == "Custom") {
          setCurrentFee(customFee);
          setTotalFee(calculateInscribeFee(customFee)["totalFee"]);
        }
        else {
          setCurrentFee(response[feeRateMode]);
          setTotalFee(calculateInscribeFee(response[feeRateMode])["totalFee"]);
        }
      }
    }
    updateFees();
    intervalId = setInterval(updateFees, 10 * 1000);
    return () => {
      clearInterval(intervalId);
    }
  }, [customFee, feeRateMode, mintAmount, detailData, unitPrice])

  useEffect(() => {
    if (sliderValue != 0) {
      let value = (sliderValue / 100) * (500 - feeRates["Normal"]) + feeRates["Normal"];
      setCustomFee(Math.floor(value));
    }
  }, [sliderValue])

  const fetchAllLrc20 = async() => {
    setLoading(true);
    try{
      if (keyAddress != "") {
        let temp = await getLRC20TokenBalance(keyAddress);
        let lrc20s = await getLRC20TokenList();  
        const results = Object.entries(temp).map(([tick, amt]) => ({ tick, amt }));
        let realData = [];
        console.log(results);
        for(let result of results) {
          if (result.amt > 0) {
            for(let lrc20 of lrc20s) {
              if (lrc20.contract == result.tick) {
                realData.push({
                  tick: lrc20.name,
                  symbol: lrc20.symbol,
                  contract: result.tick,
                  amt: result.amt / Math.pow(10, lrc20.decimals),
                  decimals: lrc20.decimals
                })
              }
              if (result.tick == "bitcoin") {
                setNativeAmount(result.amt);
              }
            }
          }
        }

        setLrc20Data(realData);
        console.log(realData);
      }
    }
    catch(e) {
      setLoading(false);
    }
    setLoading(false);
  }

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const handleDetail = async (data) => {

    if (!location.pathname.includes('/profile')) return;
    if (data.contract == "bitcoin") return;
    
    setLoading(true);

    let approvedAmount = 0;
    let tempDatas = await getLRC20TokenDetail(data.contract);
    let flag = 0;
    for(let tempData of tempDatas["state"]["_allowance"]) {
      if (tempData[0] == wallet.nostrOrdinalsAddress) {
        for(let tempData1 of tempData[1]) {
          if (tempData1[0] == lambdaAddress) {
            flag = 1;
            approvedAmount = tempData1[1]
            break;
          }
        }
      }
      if (flag == 1) break;
    }

    const {listed, listPrice} = await updateMarketplaceData(data);
    data.listed = listed;
    data.unitPrice = listPrice;
    data.approved = approvedAmount / Math.pow(10, data.decimals);

    setDetailData(data);
    setShowDetail(true);
    setUnitPrice(data.unitPrice);
    setMintAmount(data.listed);
    setLoading(false);
  }

  const handleList = () => {
    setCurrentAmount(0);
    setShow(true);
    setShowSpinner(false);
    setMode(0);
  }

  const handleUpdate = (id, amount, listPrice, decimals) => {
    setMode(1);
    setCurrentAmount(amount);
    setMintAmount(amount / Math.pow(10, parseInt(decimals)));
    setUnitPrice(listPrice);
    setCurrentId(id);
    setShow(true);
    setShowSpinner(false);
  }

  const handleDelist = async (currentId) => {
    setLoading(true);
    let params = {
      id : currentId,
      reason: "delist",
      psbt: ""
    }
    await saveMarketplaceData(params);

    let pointData = {
      address : wallet.nostrOrdinalsAddress,
      point : -1,
      reason : "Delist Lrc20",
      deleteFlag : 0
    }
    await savePointData(pointData);

    toast.success("Item delisted!");
    
    const {listed, listPrice} = await updateMarketplaceData();
    detailData.listed = listed;
    detailData.unitPrice = listPrice;
    setLoading(false);
    setDetailData(detailData);
  }

  const handleApprove = () => {
    setMode(2);
    setShow(true);
    setShowSpinner(false);
  }

  const updateMarketplaceData = async (data = detailData) => {
    let param = {
      ownerAddress: wallet.nostrOrdinalsAddress,
      ticker: data.contract,
      deleteFlag: 0
    }
    let resData = await getMarketplaceData(param);

    let listed = 0;
    let listPrice = 0;
    if (resData.total > 0) {
      for(let tempData of resData.data) {
        listed += tempData.amount / Math.pow(10, data.decimals);
        listPrice += tempData.totalPrice;
      }
      setMarketplaceData(resData.data);
    }
    else {
      setCurrentId(0);
      setMarketplaceData([]);
    }

    return {listed, listPrice}
  }

  const calculateInscribeFee = (feeRate) => {
    const text = `{"p":"lam","op":"call","contract":"${detailData?.contract}","function":"approve","args":["${lambdaAddress}",${mintAmount * Math.pow(10, detailData?.decimals)}]}`;
    let dataSize = text.length;
    let mintCount = 1;
    if (mintCount <= 0 || mintCount == NaN) mintCount = 1;
    let base_size = 160;
    let prefix = 160;
    let txsize = prefix + Math.floor(dataSize / 4);
    let inscriptionLength = mintCount;
    let inscriptionFee = padding ;
    let networkFee = Math.floor(feeRate * txsize * 1.1);
    let royalty = 0;

    let royaltyFee = royalty * mintCount;
    let serviceFee = 0;
  
    let totalFee = inscriptionFee * mintCount + networkFee * mintCount + serviceFee + lambdaApproveFee;
    return {
      "inscriptionFee": inscriptionFee,
      "networkFee": networkFee,
      "serviceFee": serviceFee,
      "royaltyFee": royaltyFee,
      "totalFee": totalFee
    }
  }

  const handleMintAmount = (e) => {
    setMintAmount(e.target.value);
  }

  const handleMintPrice = (e) => {
    setUnitPrice(e.target.value);
  }

  const handleMint = async () => {
    setShowSpinner(true);
    if (mode != 2) {
      if (mintAmount > 0 && unitPrice * mintAmount < marketplaceFeeLimit * 2) {
        toast.error("Total fee must greater thean 0.0001 BTC.");
        setShow(false);
        setShowSpinner(false);
        return;
      }
  
      let tempDatas = await getLRC20TokenDetail(detailData.contract);
      let flag = 0;
      let approvedAmount = 0;
      for(let tempData of tempDatas["state"]["_allowance"]) {
        if (tempData[0] == wallet.nostrOrdinalsAddress) {
          for(let tempData1 of tempData[1]) {
            if (tempData1[0] == lambdaAddress) {
              flag = 1;
              approvedAmount = tempData1[1];
              break;
            }
          }
        }
        if (flag == 1) break;
      }
  
      if (approvedAmount >= mintAmount * Math.pow(10, detailData?.decimals) + detailData.listed * Math.pow(10, detailData?.decimals) - currentAmount) {
        if (mode == 1){
          if (mintAmount > 0) {
            let params = {
              id : currentId,
              amount: mintAmount * Math.pow(10, detailData.decimals),
              price: unitPrice,
              reason: "edit amount",
              actualPrice: mintAmount * unitPrice - marketplaceFeeLimit,
              totalPrice: mintAmount * unitPrice,
            }
            await saveMarketplaceData(params);
            toast.success("Updated successfully!");
            detailData.unitPrice = unitPrice;
            detailData.listed = mintAmount;
          }
        }
        else if (mode == 0) {
          let params = {
            ownerAddress : wallet.nostrOrdinalsAddress,
            inscriptionId : "",
            ticker: detailData.contract,
            blk: 0,
            type: 6, // lrc20
            amount: mintAmount * Math.pow(10, detailData.decimals),
            price: unitPrice,
            psbt: "",
            reason: "sale",
            number: detailData.decimals,
            actualPrice: mintAmount * unitPrice - marketplaceFeeLimit,
            totalPrice: mintAmount * unitPrice,
          }
          await saveMarketplaceData(params);
  
          let pointData = {
            address : wallet.nostrOrdinalsAddress,
            point : 1,
            reason : "List Lrc20",
            deleteFlag : 0
          }
          await savePointData(pointData);
  
          detailData.listed = mintAmount;
          detailData.unitPrice = unitPrice;
          toast.success("Item listed!");
        }
        
        const {listed, listPrice} = await updateMarketplaceData();
        detailData.listed = listed;
        detailData.unitPrice = listPrice;
        setDetailData(detailData);
        setShow(false);
        setShowSpinner(false);

        return;
      }
      else if (approvedAmount != 0){
        toast.error("Amount exceed approved amount!");
      }
      else {
        toast.error("You need to approve the token before listing it!");
      }
      
      setShow(false);
      setShowSpinner(false);
      return;
    }
    
    if (nativeAmount < 500) {
      toast.error("Inefficient pBTC token balance!");
      setShow(false);
      setLoading(false);
      setShowSpinner(false);
      return;
    }

    const mintText = `{"p":"lam","op":"call","contract":"${detailData?.contract}","function":"approve","args":["${lambdaAddress}",${mintAmount * Math.pow(10, detailData?.decimals)}]}`;
    console.log(mintText);

    if (!typeof window) return
    if (!window.tapscript) return

    let cryptoUtils = window.cryptoUtils;
    const KeyPair = cryptoUtils.KeyPair;

    let inscriptionFee = totalFee - lambdaApproveFee;

    let privkey = bytesToHex(cryptoUtils.Noble.utils.randomPrivateKey());
    console.log(privkey);
    // privkey = "3477d0c636c7ce63604b63ee2a9fb952fcb5b5e7e461c48a5df2d58e2f50e564"

    let seckey = new KeyPair(privkey);
    let pubkey = seckey.pub.rawX;

    const ec = new TextEncoder();

    const init_script = [
      pubkey,
      'OP_CHECKSIG'
    ];
    
    const init_script_backup = [
        '0x' + buf2hex(pubkey.buffer),
        'OP_CHECKSIG'
    ];

    let init_leaf = await Tap.tree.getLeaf(Script.encode(init_script));
    let [init_tapkey, init_cblock] = await Tap.getPubKey(pubkey, {target: init_leaf});

    const test_redeemtx = Tx.create({
      vin  : [{
          txid: 'a99d1112bcb35845fd44e703ef2c611f0360dd2bb28927625dbc13eab58cd968',
          vout: 0,
          prevout: {
              value: 10000,
              scriptPubKey: [ 'OP_1', init_tapkey ]
          },
      }],
      vout : [{
          value: 8000,
          scriptPubKey: [ 'OP_1', init_tapkey ]
      }],
    });
    
    const test_sig = await Signer.taproot.sign(seckey.raw, test_redeemtx, 0, {extension: init_leaf});
    test_redeemtx.vin[0].witness = [ test_sig.hex, init_script, init_cblock ];
    const isValid = await Signer.taproot.verify(test_redeemtx, 0, { pubkey });

    if(!isValid)
    {
      alert('Generated keys could not be validated. Please reload the app.');
      return;
    }

    let files = [];

    let mimetype = "text/plain;charset=utf-8";
    let salts = "";

    for(let i = 0; i< 1; i++)
    {
      let text = mintText;
      files.push({
        text: JSON.stringify(text),
        name: textToHex(text),
        hex: textToHex(text),
        mimetype: mimetype,
        sha256: ''
      });
    }

    let inscriptions = [];
    let inscriptionAddressList = [];

    let recipientList = [];

    for (let i = 0; i < files.length; i++) {

      const hex = files[i].hex;
      const data = hexToBytes(hex);
      const mimetype = ec.encode(files[i].mimetype);

      const script = [
          pubkey,
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          ec.encode('ord'),
          '01',
          mimetype,
          'OP_0',
          data,
          'OP_ENDIF'
      ];

      const script_backup = [
          '0x' + buf2hex(pubkey.buffer),
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          '0x' + buf2hex(ec.encode('ord')),
          '01',
          '0x' + buf2hex(mimetype),
          'OP_0',
          '0x' + buf2hex(data),
          'OP_ENDIF'
      ];

      const leaf = await Tap.tree.getLeaf(Script.encode(script));
      const [tapkey, cblock] = await Tap.getPubKey(pubkey, { target: leaf });

      let inscriptionAddress = Address.p2tr.encode(tapkey, encodedAddressPrefix);

      let prefix = 160;

      let txsize = prefix + Math.floor(data.length / 4);

      inscriptionAddressList.push(inscriptionAddress);

      inscriptions.push(
        {
          leaf: leaf,
          tapkey: tapkey,
          cblock: cblock,
          inscriptionAddress: inscriptionAddress,
          txsize: txsize,
          fee: inscriptionFee - padding,
          script: script_backup,
          script_orig: script
        }
      );

      recipientList.push ({
        address: inscriptionAddress,
        amountSats: BigInt(inscriptionFee),
      })
    }
    
    let _fundingAddress = Address.p2tr.encode(init_tapkey, encodedAddressPrefix);

    // get payment utxos

    let tempfeerate = currentFee;
    if (wallet.domain == "xverseWallet") tempfeerate = Math.ceil(currentFee * 1.2);
    const paymentUtxos = await getPaymentUtxos(wallet.nostrPaymentAddress, inscriptionAddressList, inscriptionFee, tippingAddress, lambdaApproveFee, wallet.paymentPublicKey, tempfeerate, 1, wallet.domain)

    let isSuccess = true;

    if (paymentUtxos.status == "fail") {
      alert("Insufficient balance.");
      setLoading(false);
      setShow(false);
      setShowSpinner(false);
      isSuccess = false;
      return;
    }

    try{
      if (wallet.domain == "tapwallet") {
        const signedPsbt = await window.tapwallet.signPsbt(paymentUtxos.psbt);
        const txid = await window.tapwallet.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "unisat") {
        const signedPsbt = await window.unisat.signPsbt(paymentUtxos.psbt);
        const txid = await window.unisat.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "okxwallet") {
        const signedPsbt = await window.okxwallet.bitcoin.signPsbt(paymentUtxos.psbt);
        const txid = await window.okxwallet.bitcoin.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "xverseWallet") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          await signTransaction({
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
              
            },
            onCancel: () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                setShow(false);
                setShowSpinner(false);
                return;
            },
          });
  
        }
        else {
          alert("Insufficient balance.");
          setLoading(false);
          setShow(false);
          isSuccess = false;
          setShowSpinner(false);
          return;
        }
      }
      if (wallet.domain == "magiceden") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

          await signTransaction({
            getProvider: async () =>
              magicedenWallets[0].features['sats-connect:'].provider,
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
            },
            onCancel: () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                setShow(false);
                setShowSpinner(false);
                return;
            },
          });
  
        }
        else {
          alert("Insufficient balance.");
          setLoading(false);
          setShow(false);
          isSuccess = false;
          setShowSpinner(false);
          return;
        }
      }
    }
    catch(e)
    {
      console.log(e);
      alert("Payment rejected by user. Try again.");
      isSuccess = false;
      setLoading(false);
      setShow(false);
      setShowSpinner(false);
      return;
    }
    
    if (isSuccess) {
      // save private key and public key
      await saveTokenTransferMintData({
        key: privkey,
        address: wallet.nostrOrdinalsAddress,
        text: mintText
      });

      let transactionData;
      while(true)
      {
        transactionData = await getMempoolUtxos(inscriptions[0].inscriptionAddress);
        if (transactionData.length >= 1){
          break;
        }
        await waitSomeSeconds(2);
      }

      for (let i = 0; i < inscriptions.length; i++) {
        await inscribe(inscriptions[i], i, transactionData[0].txid, transactionData[0].value, seckey);
      }
    }

    setLoading(false);
    setShowSpinner(false);
    setShow(false);
  }

  const inscribe = async(inscription, vout, txid2, amt2, seckey) => {
    let _toAddress;
    let _script;
    let toAddress = wallet.nostrOrdinalsAddress;
    if(toAddress.startsWith('tb1q') || toAddress.startsWith('bc1q'))
    {
        _toAddress = Address.p2wpkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_0', _toAddress ];
        console.log('using p2wpkh', _script);
    }
    else if(toAddress.startsWith('1') || toAddress.startsWith('m') || toAddress.startsWith('n'))
    {
        _toAddress = Address.p2pkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2pkh.scriptPubKey(_toAddress);
        console.log('using p2pkh', _script);
    }
    else if(toAddress.startsWith('3') || toAddress.startsWith('2'))
    {
        _toAddress = Address.p2sh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2sh.scriptPubKey(_toAddress);
        console.log('using p2sh', _script);
    }
    else
    {
        _toAddress = Address.p2tr.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_1', _toAddress ];
        console.log('using p2tr', _script);
    }

    const redeemtx = Tx.create({
        vin  : [{
            txid: txid2,
            vout: vout,
            prevout: {
                value: amt2,
                scriptPubKey: [ 'OP_1', inscription.tapkey ]
            },
        }],
        vout : [{
            value: padding,
            scriptPubKey: _script
        }],
    });

    const sig = await Signer.taproot.sign(seckey.raw, redeemtx, 0, {extension: inscription.leaf});
    redeemtx.vin[0].witness = [ sig.hex, inscription.script_orig, inscription.cblock ];

    console.dir(redeemtx, {depth: null});

    let rawtx2 = Tx.encode(redeemtx).hex;
    let _txid2;

    while(true) {
      _txid2 = await pushBTCpmt( rawtx2 );
      if (_txid2.length == 64) {
        setTxid(_txid2);
        setShowResult(true);
        break;
      }
      await waitSomeSeconds(3);
    }
  }

  const handleCustomFee = (value) => {
    const sanitizedValue = value.replace(/[^0-9.]/g, '');
    setCustomFee(sanitizedValue);
  }

  return (
    <div className="flex flex-col w-full">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      {
        showDetail ? 
        <div className="flex flex-col">
          <div className="mt-3 font-bold text-primary text-[24px] flex flex-row gap-2 items-center">
            <ArrowLeftIcon strokeWidth={3} className="h-5 w-5 cursor-pointer" onClick={() => setShowDetail(false)}/>
            <div className="sm:text-[24px] text-[20px]" >{detailData.symbol}</div>
          </div>
          <div className="flex flex-row-reverse w-full">
            <a href={`https://apps.lambdaprotocol.io`} target="_blank" rel="noopener noreferrer" >
              <div className="px-4 py-2 bg-primary hover:bg-primary-hover rounded-md text-white cursor-pointer">
                Wrap BTC to pBTC
              </div>
            </a>
          </div>
          <div className="flex flex-col gap-3 md:p-6 p-3 justify-center items-center rounded-md border-[1px] border-borderColor border-solid max-w-[500px] m-auto mt-5">
            <div className="flex flex-row gap-2 items-center md:min-w-[300px] min-w-[250px] justify-between">
              <div>Total Amount:</div>
              <div className="font-bold md:text-[20px] text-[16px] text-primary text-right">{detailData.amt}</div>
            </div>
            <div className="flex flex-row gap-2 items-center md:min-w-[300px] min-w-[250px] justify-between">
              <div>Approved Amount:</div>
              <div className="font-bold md:text-[20px] text-[16px] text-primary text-right">{detailData.approved}</div>
            </div>
            <div className="flex flex-row gap-2 items-center md:min-w-[300px] min-w-[250px] justify-between">
              <div>Listed Amount:</div>
              <div className="font-bold md:text-[20px] text-[16px] text-primary text-right">{detailData.listed}</div>
            </div>
            <div className="flex flex-row gap-2 md:min-w-[300px] min-w-[250px] justify-between">
              <div className="md:mt-1 mt-0">Total Price:</div>
              <div className="flex flex-col gap-1">
                <div className="font-bold md:text-[20px] text-[16px] text-primary text-right">( {formatFloat(detailData.unitPrice / Math.pow(10, 8), 6)} BTC )</div>
                <div className="font-bold md:text-[20px] text-[16px] text-primary text-right">( {detailData.unitPrice} SATS )</div>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-2">
              <div className="bg-primary px-10 py-2 text-white rounded-md cursor-pointer hover:bg-primary-hover" onClick = {() => handleApprove()}>APPROVE</div>
              <div className="bg-primary px-10 py-2 text-white rounded-md cursor-pointer hover:bg-primary-hover" onClick = {() => handleList()}>LIST</div>
            </div>
          </div>
          <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 w-full mt-8">
          {
              marketplaceData.map((data) => 
                  <div className="w-full max-w-[320px] m-auto rounded-md border-borderColor border-[1px] border-solid bg-gray-900 text-[14px]">
                    <div className="flex flex-row justify-center text-[24px] font-bold mt-5">
                      {amountFormat(formatFloat(data.amount / Math.pow(10, parseInt(data.number)), 6))}
                    </div>
                    <div className="flex flex-row justify-center items-center mt-3 gap-2">
                      <span className="font-bold text-primary text-[24px]">{formatFloat(data.price / Math.pow(10, 8), 6)}</span>
                      <span className="text-gray-400">sats/1</span>
                    </div>
                    <div className="flex flex-row justify-center">
                      ${formatFloat(data.price / Math.pow(10, 8) * price, 6)}
                    </div>
                    <div className="bg-[#121212] mt-2 pb-3 px-4 rounded-b-md">
                    <div className="flex flex-row justify-between mt-2 pt-1">
                      <div className="flex flex-row gap-1 items-center">
                        <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="9" fill="#e91d9b"></circle><path d="M13.14 7.965c.185-1.143-.624-1.672-1.078-1.964-.452-.292-1.143-.502-1.143-.502l.442-1.78-1.02-.256-.442 1.78-.815-.206.442-1.78L8.494 3l-.442 1.78-2.157-.542-.262 1.056s1.065.266 1.182.297c.12.029.104.09.094.13-.01.04-1.34 5.405-1.36 5.476-.016.072-.028.113-.127.09l-1.131-.284L4 12.176l2.088.523-.423 1.702 1.113.279.423-1.703.693.174-.423 1.703 1.06.266.424-1.702.798.2c.53.133 2.544.158 2.982-1.611.439-1.77-1.012-2.519-1.012-2.519s1.23-.38 1.417-1.523Zm-2.417 3.282c-.21.847-1.155.912-1.37.857l-1.895-.474.56-2.262 1.965.492c.377.096.951.54.74 1.387Zm.35-3.445c-.224.902-1.124.861-1.408.791L8.28 8.246l.534-2.153 1.264.316c.216.054 1.22.492.995 1.393Z" fill="#fff"></path></svg>
                        <span className="text-primary">{formatFloat(data.price * data.amount / Math.pow(10, parseInt(data.number)) / Math.pow(10, 8), 6)}</span>
                      </div>
                      <div className="text-gray-400">${formatFloat(data.price * data.amount * price / Math.pow(10, parseInt(data.number)) / Math.pow(10, 8), 4)}</div>
                    </div>
                    <div className="w-full flex flex-row items-center justify-center mt-4 mb-2 p-1 gap-1">
                      <div className="bg-primary px-10 py-2 text-white rounded-md cursor-pointer hover:bg-primary-hover" onClick = {() => handleUpdate(data.id, data.amount, data.price, data.number)}>UPDATE</div>
                      <div className="bg-primary px-10 py-2 text-white rounded-md cursor-pointer hover:bg-primary-hover" onClick = {() => handleDelist(data.id)}>DELIST</div>
                    </div>
                  </div>
                </div>
              )
          }
          </div>
        </div>
        :
        <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 w-full mt-5">
          {
            lrc20Data.length > 0 ? 
              lrc20Data.map((data) => {
                return <Lrc20Card key={uuidv4()} data={data} handle={handleDetail}/>
              }) : <>No LRC-20 tokens.</>
          }
        </div>
      }
      
      {
        total > 1 ? 
        <div className="flex flex-row items-center gap-8 justify-end mt-5">
          <IconButton
            size="sm"
            variant="outlined"
            onClick={prev}
            disabled={page === 1}
            className="bg-primary hover:bg-primary-hover"
          >
            <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
          <Typography color="white" className="font-normal">
            Page <strong className="text-primary">{page}</strong> of{" "}
            <strong className="text-primary">{total}</strong>
          </Typography>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={next}
            disabled={page === total}
            className="bg-primary hover:bg-primary-hover"
          >
            <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
        </div>
        :
        <></>
      }
      <Dialog
        open={show}
        size={"sm"}
        className="bg-black border-solid border-[1px] border-gray-600"
      >
        <DialogBody>
          <div className="flex flex-col gap-4 w-full items-center sm:p-3 p-1">
            <div className="font-bold text-[32px] text-primary mt-4">
              { mode == 0 ? 'LIST' : mode == 1 ? 'UPDATE' : 'APPROVE'}
            </div>
            <div className="text-[24px] font-bold text-white">
              {detailData.symbol}
            </div>
            {
              mode != 2 ?
              <>
                <div className="w-full font-bold text-white sm:text-[28px] text-[24px] text-center">
                  ${formatFloat(unitPrice * mintAmount * price / Math.pow(10, 8), 2)}
                </div>
                <div className="w-full max-w-[320px] grid grid-cols-2 m-auto">
                  <div className="w-full font-bold text-white sm:text-[16px] text-[14px] text-left">
                    <div>Price:</div>
                    <div>Price per unit:</div>
                  </div>
                  <div className="w-full font-bold text-white sm:text-[16px] text-[14px] text-right mb-2">
                    <div>{formatFloat(unitPrice * mintAmount / Math.pow(10, 8), 6)} BTC</div>
                    <div>{formatFloat(unitPrice / Math.pow(10, 8), 6)} BTC</div>
                  </div>
                </div>
              </>
              : <></>
            }
            <div className="flex flex-row items-center gap-1 justify-center">
              <div className="sm:w-[270px] w-[200px]">
                <Input color="white" label = "Insert Amount" value = {mintAmount} onChange = {(e) => {handleMintAmount(e)}}/>
              </div>
              {
                mode == 2 ?
                <></>
                :  
                <span className="font-bold text-transparent ml-1">sats</span>
              }
            </div>
            {
              mode == 2 ?
                <></>
                :
                <div className="flex flex-row items-center gap-1 justify-center">
                  <div className="sm:w-[270px] w-[200px]">
                    <Input color="white" label = "Insert Price" value = {unitPrice} onChange = {(e) => {handleMintPrice(e)}}/>
                  </div>
                  <span className="font-bold ml-1">sats</span>
                </div>
            }
            {
              mode != 2 ?
              <></>
              :
              <>
                <div className="w-full text-white font-bold">
                  Network Fee:
                </div>
                <div className="grid grid-cols-3 gap-3 w-full">
                  <FeeRateCardNew header={feeRateTabs[1]} rate={feeRates[feeRateTabs[1]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[1])}}/>
                  <FeeRateCardNew header={feeRateTabs[2]} rate={feeRates[feeRateTabs[2]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[2])}}/>
                  <FeeRateCardNew header={feeRateTabs[3]} rate={customFee} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[3])}}/>
                </div>
                <div className="sm:text-[24px] text-[16px] font-bold text-white">
                  Total Fee: {totalFee / Math.pow(10, 8)} BTC
                </div>
                {
                  feeRateMode == "Custom" ? 
                  <div className="flex flex-row gap-3 items-center">
                    <Slider className="sm:flex hidden" color="blue" value = {sliderValue} onChange = {(e) => setSliderValue(e.target.value)}/>
                    <div className="sm:max-w-[200px] w-full">
                      <Input type="text" color="white" label = "Custom Fee" className="sm:max-w-[200px] w-full" value={customFee} onChange = {(e) => {handleCustomFee(e.target.value)}}/>
                    </div>
                  </div>
                  : 
                  <></>
                }
              </>
            }
            {
              showSpinner ? 
                <Spinner className="h-12 w-12" color="red"/>:
                <div className="flex flex-row gap-3">
                  <button className="bg-primary hover:bg-primary-hover text-white rounded-md sm:px-10  px-6 font-bold py-2" onClick={() => handleMint()}>{mode == 0 ? 'List' : mode == 1 ? 'Update' : 'Approve'}</button>
                  <button className="bg-[#212121] text-white rounded-md sm:px-10 px-6 font-bold py-2" onClick={() => setShow(false)}>Return</button>
                </div>
            }
          </div>
        </DialogBody>
      </Dialog>
      <Dialog
        open={showResult}
        size={"sm"}
        className="bg-black border-solid border-[1px] border-gray-600"
      >
        <DialogBody>
          <div className="flex flex-col gap-4 w-full items-center p-3">
            <div className="flex flex-row w-full justify-center items-center gap-3 mt-6 sm:text-[32px] text-[24px] font-bold text-primary">
              <CheckCircleIcon strokeWidth={2} className="h-16 w-16" /> <span>Listed successful.</span>
            </div>
            <div className="break-all">
              Please tap the inscription(
                <a href={`https://ordinals.com/inscription/${txid}i0`}  target="_blank" className=" text-blue-800 font-bold">
                  {txid + 'i0'}
                </a>
              ) to excute the operation.
            </div>
            <button className="bg-[#212121] text-white rounded-md px-10 font-bold py-2" onClick={() => setShowResult(false)}>Return</button>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  )
}
